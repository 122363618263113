import { sessionStorage } from "@afound/common";
import { isNull } from './util';
window.Afound = window.Afound || {};
window.Afound.data_collection = {};

window.Afound.data_collection.gtm = {
   ensure_data_layer_exists: function () {
      if (isNull(window.dataLayer))
         window.dataLayer = [];
   },
   data_layer: {
      push: function (obj, eventname) {
         if (isNull(obj, 'object') || (isNull(obj.event, 'string') && isNull(eventname)))
            return;

         window.Afound.data_collection.gtm.ensure_data_layer_exists();

         if (!isNull(eventname))
            obj.event = eventname;

         window.dataLayer.push(obj);
      },
      get: function (param) {
         window.Afound.data_collection.gtm.ensure_data_layer_exists();

         let dl_obj = {};

         for (let i = 0; i < window.dataLayer.length; i++)
            for (let key in window.dataLayer[i])
               dl_obj[key] = window.dataLayer[i][key];

         if (!isNull(param, 'string'))
            return dl_obj[param];

         return dl_obj;
      },
   },
   ecommerce: {
      property_mappings: {
         'Name': { ga_name: 'name', impressions: true, click: true, detail: true, purchase: true },
         'DisplayPrice': { ga_name: 'price', impressions: true, click: true, detail: true, purchase: true },
         'BrandName': { ga_name: 'brand', impressions: true, click: true, detail: true, purchase: true },
         'Code': { ga_name: 'id', impressions: true, click: true, detail: true, purchase: true },
         'PercentOff': { ga_name: 'dimension1', impressions: true, click: true, detail: true, purchase: true },
         'MaterialsForGtm': { ga_name: 'dimension6', impressions: true, click: true, detail: true, purchase: true },
         'ProductMarkerText': { ga_name: 'dimension7', impressions: true, click: true, detail: true, purchase: true },
         'Stock': { ga_name: 'dimension8', impressions: true, click: true, detail: true, purchase: true },
         'SellerName': { ga_name: 'dimension9', impressions: true, click: true, detail: true, purchase: true },
         'Variants': { ga_name: 'dimension10', impressions: true, click: true, detail: true, purchase: true },
         'OfferCode': { ga_name: 'dimension13', impressions: true, click: true, detail: true, purchase: true },
         'MainImageUrl': { ga_name: 'dimension18', impressions: true, click: true, detail: true, purchase: true },
         'Position': { ga_name: 'dimension20', impressions: true, click: true, detail: true, purchase: true },
      },
      send_product_click: function (obj) {
         const href = obj.getAttribute('href');
         if (href) {
            localStorage.setItem('lastClickedProductHref', href);
         }

         if (!obj.getAttribute('data-gtm-product')) {
            return;
         }

         const product = JSON.parse(obj.getAttribute('data-gtm-product'));
         if (isNull(product))
            return;
         const product_carousel_name = obj.getAttribute('data-gtm-list');
                  
         sessionStorage.setItem("af_panel", product_carousel_name);
         
         // base push
         let product_obj = {
            brand: null,
            category: null,
            dimension1: null,
            dimension6: null,
            dimension7: null,
            dimension8: null,
            dimension9: null,
            dimension10: null,
            dimension13: null,
            dimension18: null,
            dimension20: window.Afound.data_collection.gtm.data_layer.get('page').contentType,
            id: null,
            name: null,
            price: null,
            variant: null,
         };

         // overwrite values of base push if found
         for (let prop in product) {
            let mapping = window.Afound.data_collection.gtm.ecommerce.property_mappings[prop];
            if (!isNull(mapping) && mapping.click)
               product_obj[mapping.ga_name] = product[prop];
         }

         let product_push = {
            'event': 'productClickEvent',
            'ecommerce': {
               'currencyCode': (window.Afound.product_list && window.Afound.product_list.currency) || product.CurrencyCode,
               'click': {
                  'actionField': {
                     'action': 'click',
                     'list': product_carousel_name || location.pathname,
                  },
                  'products': [product_obj],
               },
            },
         };

         window.Afound.data_collection.gtm.data_layer.push(product_push);
      },
   },
};
