import ko from 'knockout';

function Accordion(el) {
  // ----------------------------------------------------------------------- //
  // selectors                                                               //
  // ----------------------------------------------------------------------- //

  const accordion = el;

  // ----------------------------------------------------------------------- //
  // initialize                                                              //
  // ----------------------------------------------------------------------- //

  this.init = function (selected) {
    // Add event listeners
    const headings = accordion.querySelectorAll('.accordion__item__header');
    for (let i = 0; i < headings.length; i++) {
      headings[i].addEventListener('click', function () {
        accordionToggleItem(this); 
      }, false);
    }
    // Pre-select accordion
    const selectedAccordion = accordion.querySelectorAll('.' + selected);
    for (let i = 0; i < selectedAccordion.length; i++) {
      selectedAccordion[i].classList.add('active');
    }
  };


  // ----------------------------------------------------------------------- //
  // toggle active item                                                      //
  // ----------------------------------------------------------------------- //
  function accordionToggleItem(el) {
    const item = el.parentNode;
    if (!item.classList.contains('active')) {
      const activeItems = accordion.querySelectorAll('.active');
      for (let i = 0; i < activeItems.length; i++) {
        activeItems[i].classList.remove('active');
      }
    }
    item.classList.toggle('active');
  }
}

// ----------------------------------------------------------------------- //
// bindinghandler                                                          //
// ----------------------------------------------------------------------- //
ko.bindingHandlers.accordion = {
  update: (el, valueAccessor) => {

    const selectedItem = valueAccessor();
    const element = new Accordion(el);

    element.init(selectedItem); // initialize

  },
};