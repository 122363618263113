import ko from 'knockout';

class CollapsibleTextHandler {
  update(element) {
    const isOverflow = element.scrollHeight > element.offsetHeight;
    if (!isOverflow) {
      return; // do nothing
    }

    element.classList.add('collapsible-text--collapsed');

    element.addEventListener('click', () => {
      if (element.classList.contains('collapsible-text--expanded')) {
        element.classList.remove('collapsible-text--expanded');
        element.classList.add('collapsible-text--collapsed');
      } else {
        element.classList.remove('collapsible-text--collapsed');
        element.classList.add('collapsible-text--expanded');
      }
    });
  }
}
ko.bindingHandlers.collapsibleText = new CollapsibleTextHandler();
