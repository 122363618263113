/* eslint-disable no-unexpected-multiline */
import * as ko from 'knockout';

import {
  newsletterSubscribe,
  newsletterRegisterUser,
  NEWSLETTER_SUBSCRIBE_SUCCESS,
  NEWSLETTER_SUBSCRIBE_ERROR,
  NEWSLETTER_REGISTER_USER_SUCCESS,
  NEWSLETTER_REGISTER_USER_ERROR,
} from './action-creators';
import { HISTORY_UPDATED, historyChange } from '../../Common/action-creators';
import {
  createGtmOnSubscription,
  createGtmOnCreateAccount,
  GTM_SIGNUP_EVENT_TYPE_SUCCESS,
  GTM_SIGNUP_EVENT_TYPE_ERROR,
  GTM_SUBSCRIPTION_EVENT_LABEL_NEWSLETTER,
  GTM_SUBSCRIPTION_EVENT_LABEL_NEWSLETTER_PRESHOP,
  GTM_ACCOUNT_EVENT_TYPE_SUCCESS,
  GTM_ACCOUNT_EVENT_TYPE_ERROR,
} from '../../Tracking/gtm-action-creators';
import utils from "../../New/Common/utils";

const newsletterVM = dispatcher => {
  let hasPreshopSignupFlow = false;

  const emailField = document.getElementById('username');
  const emailError = document.querySelector("#username + .form-control__error");

  if (emailField) {
    emailField.addEventListener("input", (event) => {
      if (emailField.validity.valid) {
        emailError.textContent = "";
        emailError.className = "error";
        emailField.className = "form-control__input";
      }
    });
  }

  const validateEmail = (element, errorElement) => {
    if (element.validity.valueMissing) {
      errorElement.textContent = INITIALDATA.validationMessages.required;
      errorElement.className = "error form-control__error";
      element.className = "form-control__input error";
      return false;
    }

    const [isEmailValid, transformed] = utils.validateTransformedEmail(element.value);
    element.value = transformed;

    if (!isEmailValid) {
      errorElement.textContent = INITIALDATA.validationMessages.email;
      errorElement.className = "error form-control__error";
      element.className = "form-control__input error";
      return false;
    }

    return true;
  };

  let vm = {
    isAwaitingResponse: ko.observable(false).extend({ rateLimit: 500 }),
    departmentPreference: ko.observable(),
    showDepartmentRequiredWarning: ko.observable(false),
    formErrors: ko.observableArray(),
    isLoggedInState: ko.observable(),
    showStartText: ko.observable(true),
    showDivider: ko.observable(true),
    showThankyouText: ko.observable(false),
    showRegisterUserForm: ko.observable(false),
    showAccountPolicyAcceptWarning: ko.observable(),
    accountPolicyAccepted: ko.observable(),
    showDirectMarketingPolicyAcceptWarning: ko.observable(),
    directMarketingPolicyAccepted: ko.observable(),
    validateForm: () => {
      let isFormValid = true;

      if (!vm.showRegisterUserForm() && !vm.departmentPreference()) {
        vm.showDepartmentRequiredWarning(true);
        isFormValid = false;
      }

      if (!vm.directMarketingPolicyAccepted()) {
        vm.showDirectMarketingPolicyAcceptWarning(true);
        isFormValid = false;
      }

      if (vm.showRegisterUserForm() && !vm.accountPolicyAccepted()) {
        vm.showAccountPolicyAcceptWarning(true);
        isFormValid = false;
      }

      if (!validateEmail(emailField, emailError)) {
        isFormValid = false;
      }

      return isFormValid;
    },
    submitSubscriptionForm: (form) => {
      hasPreshopSignupFlow = form.hasAttribute('preshop-signup-flow');
      vm.formErrors([]);
      if (!vm.validateForm()) {
        return;
      }

      vm.isAwaitingResponse(true);
      const formData = new FormData(form);
      if (vm.directMarketingPolicyAccepted()) {
         formData.append('SubscribeToNewsletter', 'true');
      }

      dispatcher.dispatch(
        (vm.showRegisterUserForm() ? newsletterRegisterUser : newsletterSubscribe)
        (formData)
      );
    },
  };

  vm.directMarketingPolicyAccepted.subscribe((newValue) => {
    vm.showDirectMarketingPolicyAcceptWarning(!newValue);
  });

  vm.accountPolicyAccepted.subscribe((newValue) => {
    vm.showAccountPolicyAcceptWarning(!newValue);
  });

  vm.departmentPreference.subscribe((newValue) => {
    vm.showDepartmentRequiredWarning(!newValue);
  });

  vm.toggleRegister = () => {
    vm.showStartText(false);

    if (hasPreshopSignupFlow) {
      vm.showThankyouText(true);
    } else {
      // We previously had logic here to sometimes a login page
      // However, this was ALWAYS shown to customers prompting them to log in first
      // AND it didn't make sense as they actually was added as subscribers any way
      vm.showThankyouText(true);
    }
  };
  dispatcher.subscribe(action => {
    let gaSubscribeEventLabel = hasPreshopSignupFlow ? GTM_SUBSCRIPTION_EVENT_LABEL_NEWSLETTER_PRESHOP : GTM_SUBSCRIPTION_EVENT_LABEL_NEWSLETTER;

    switch (action.type) {
      case NEWSLETTER_REGISTER_USER_SUCCESS:
        try {
          createGtmOnCreateAccount({ action: GTM_ACCOUNT_EVENT_TYPE_SUCCESS, message: 'newsletter page' });
        } catch (e) {
          // noop
        }
        if (action.data.ReturnUrl) {
          window.location = window.location.origin + action.data.ReturnUrl;
        }
        break;
      case NEWSLETTER_REGISTER_USER_ERROR:
        try {
          createGtmOnCreateAccount({ action: GTM_ACCOUNT_EVENT_TYPE_ERROR, message: 'error:newsletter page' });
        } catch (e) {
          // noop
        }
        vm.isAwaitingResponse(false);
        vm.formErrors(action.data);
        break;
      case NEWSLETTER_SUBSCRIBE_SUCCESS:
        vm.isAwaitingResponse(false);
        vm.isLoggedInState(action.data.IsLoggedIn);

        if (hasPreshopSignupFlow) {
          vm.toggleRegister();
        } else {
          if (!action.data.Redirect) {
            if (window.location.hash.indexOf('#step2') === -1) {
              if (window.history.pushState !== undefined) {
                window.history.pushState(null, null, '#step2');
              } else {
                window.location.hash = '#step2';
              }
              dispatcher.dispatch(historyChange(window.location));
            }
          }
        }
        
        if (action.data.Success) {
          createGtmOnSubscription({
            action: GTM_SIGNUP_EVENT_TYPE_SUCCESS,
            label: gaSubscribeEventLabel,
            departmentPreference: vm.departmentPreference(),
          });
        }
        
        if (action.data.Redirect) {
          window.location = window.location.origin + action.data.ReturnUrl;
        }
        break;
      case HISTORY_UPDATED:
        setTimeout(() => {
          if (action.data.hash.indexOf('#step2') !== -1) {
            vm.toggleRegister();
          }
        }, 10); // let it appear before we look for it
        break;
      case NEWSLETTER_SUBSCRIBE_ERROR:
        try {
          // eslint-disable-next-line no-undef
          createGtmOnSubscription({
            action: GTM_SIGNUP_EVENT_TYPE_ERROR,
            label: 'error:' + gaSubscribeEventLabel,
            departmentPreference: vm.departmentPreference,
          });
        } catch (e) {
          // noop
        }
        vm.isAwaitingResponse(false);
        vm.formErrors(action.data);
        break;
    }
  });
  return vm;
};
export default newsletterVM;
