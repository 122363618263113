import { Esales } from '../../Vendor/esales.min.js';
import constants from './constants';

const esalesNotifier = () => {

  const esales = new Esales({
    pathToNotifier: '/api/v1/esales/notify',
    postJSON: true,
  });

  // internal function copied from esales.min.js
  const _findListenedElement = (_x) => {
    let _again = true;

    _function: while (_again) {
      let element = _x;
      _again = false;


      let classList = element.classList,
        i;
      for (i = 0; i < classList.length; i++) {
        if (classList[i] === constants.Esales.ClickClass || classList[i] === constants.Esales.AddToCartClass) {
          return element;
        }
      }

      if (element.nodeName !== 'BODY' && element.parentElement) {
        _x = element.parentElement;
        _again = true;
        classList = i = undefined;
        continue _function;
      } else {
        return null;
      }
    }
  };

  const esalesClickListener = (event) => {
    let target = event.target || event.srcElement;
    target = _findListenedElement(target);
    if (target) {
      let ticket = esales.findTicket(target);
      if (target.classList.contains(constants.Esales.ClickClass)) {
        esales.notifyClick(ticket);
      } else if (target.classList.contains(constants.Esales.AddToCartClass)) {
        esales.notifyAddingToCart(ticket);
      }
    }
  };

  /*
   * Attaches eSales click event handler to elements docorated with 'es-panel-content' css class
   * The built in method 'esales.startAutomaticListeners()` doesn't work on elements that display as popup eg. cart recommendation
   * To overcome this, we attach the click event to the container element of this pop-up
  */
  const startCustomListener = () => {
    const panels = document.getElementsByClassName(constants.Esales.PanelClass);

    for (let i = 0; i < panels.length; i++) {
      panels[i].addEventListener('click', esalesClickListener, false);
    }
  };

  const stopCustomListener = () => {
    const panels = document.getElementsByClassName(constants.Esales.PanelClass);

    for (let i = 0; i < panels.length; i++) {
      panels[i].removeEventListener('click', esalesClickListener, false);
    }
  };

  return {
    startAutomaticListener: () => {
      esales.startAutomaticListeners();
      startCustomListener();
    },
    stopAutomaticListener: () => {
      esales.stopAutomaticListeners();
      stopCustomListener();
    },
  };
};

export default esalesNotifier;
