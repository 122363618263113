class GtmEvent {
  constructor(promotions) {
    this.data = {};
  }

  push() {
    if (!dataLayer) {
      return;
    }
    dataLayer.push(this.data);
  }
}

/**
 * Event type for impressions
 */
export class ImpressionEvent extends GtmEvent {
  /**
   * Constructs an event with an array for impressions
   * @param {Array<Object>} impressions - array of impressions 
   * @param {String} impressions.id - EPiServer block type name
   * @param {String} impressions.name - EPiServer block name
   * @param {String} impressions.creative - Heading of block
   * @param {String} impressions.position - Index position in the DOM
   * @param {String} impressions.url - Destination url
   */
  constructor(impressions) {
    super();

    this.data = {
      'event': 'promoImpressionEvent',
      'ecommerce': {
        'promoView': {
          'promotions': impressions,
        },
      },
    };
  }
}

/**
 * Event type for clicks
 */
export class ClickEvent extends GtmEvent {
  /**
   * Event construction
   * @param {String} id - EPiServer block type name
   * @param {String} name - EPiServer block name
   * @param {String} creative - Heading of block
   * @param {String} position - Index position in the DOM
   * @param {String} targetUrl - Destination url of click
   */
  constructor(id, name, creative, position, targetUrl) {
    super();

    this.data = {
      'event': 'promotionClick',
      'ecommerce': {
        'promoClick': {
          'promotions': [
            {
              id,
              name,
              creative,
              position,
              targetUrl,
            },
          ],
        },
      },
    };
  }
}

/**
 * Event type for product clicks
 */
export class ProductClickEvent extends GtmEvent {
  /**
   * Event construction
   * @param {String} currency - Product currency
   * @param {String} list - GTM List name
   * @param {String} name - Product name
   * @param {String} code - Product code
   * @param {String} price - Product price
   * @param {String} brandName - Product brand name
   * @param {String} marker - Product marker
   * @param {String} percentOff - Product percentOff
   * @param {String} view - GTM View name
   */
  constructor(currency, list, name, code, price, brandName, marker, percentOff, view) {
    super();

    this.data = {
      'event': 'productClickEvent',
      'ecommerce': {
        'currencyCode': currency,
        'click': {
          'actionField': { 'list': list },
          'products': [
            {
              'name': name,
              'id': code,
              'price': price ? price.toString() : null,
              'brand': brandName,
              'category': null,
              'variant': null,
              'dimension6': null,
              'dimension7': (marker !== '' && marker !== undefined) ? marker : null,
              'dimension8': null,
              'dimension1': percentOff ? percentOff.toString() : null,
              'dimension9': null,
              'dimension10': null,
              'dimension20': view,
            },
          ],
        },
      },
    };
  }
}
