import { sessionStorage } from "@afound/common";
import { ClickEvent, ImpressionEvent, ProductClickEvent } from "./events";
import { PAGE_OBSERVE_CLICK, PAGE_OBSERVE_IMPRESSION } from "./tracking-action-creators";
import appDispatcher from "../Common/appDispatcher";

let isUsingPolyfill = false;

/**
 * Asynchronously load the intersection observer polyfill, should only be used if the browser does not support it natively
 * @returns {Promise} A promise with the loaded polyfill
 */
const loadPolyfill = () => {
   return import(/* webpackChunkName: "intersectionObserverPolyfill" */ "intersection-observer")
      .then((chunk) => {
         return chunk;
      })
      .catch((error) => "An error occurred while loading the intersection observer polyfill");
};

/**
 * Ensure browser has support for IntersectionObserver
 * @returns {Promise} A promise that the IntersectionObserver exists, either natively or polyfilled
 */
const ensureIntersectionObserverExists = () => {
   return new Promise((resolve, reject) => {
      if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window) {
         resolve(true);
         return;
      }

      loadPolyfill()
         .then((polyfill) => {
            isUsingPolyfill = true;
            resolve(true);
         })
         .catch((error) => reject(false));
   });
};

const onIntersectionChange = (changes) => {
   changes.forEach((change) => {
      let element = change.target;
      if (!element.hasObserved && change.isIntersecting) {
         element.hasObserved = true;
         onImpression(element);
      }
   });
};

let intersectionObserver = null;

/**
 * Bind click event for tracking
 * @param {HTMLElement} element
 */
const observeClickEvents = (element) => {
   const links = element.querySelectorAll("a");
   Array.from(links).forEach((link) => {
      link.addEventListener("mousedown", onMouseDown);
      link.addEventListener("mouseup", onMouseUp);
      link.addEventListener("click", onClick);
   });
};

/**
 * Handle impression event for element
 * @param {HTMLElement} element
 */
function onImpression(element) {
   intersectionObserver.unobserve(element);

   const elementData = getElementData(element);
   if (!elementData || elementData.id === "") {
      return;
   }

   schedulePendingEvents({
      type: "impression",
      data: elementData,
   });
}

/** Handle mousemove state during mousedown event */
let hasDraggedMouse = false;

/** Set state to hasDragged, to prevent drag event from firing click event */
function onMouseMove() {
   hasDraggedMouse = true;
}

/**
 * Handle the click trakcking for the element
 * @param {Event} event - the DOM click event
 */
function onMouseDown(event) {
   event.target.addEventListener("mousemove", onMouseMove);
}

/**
 * Handle the click trakcking for the element if mouse has not moved during mousedown
 * @param {Event} event - the DOM click event
 */
function onMouseUp(event) {
   if (!hasDraggedMouse) {
      trackClickEvent(event);
   }

   hasDraggedMouse = false;
   event.target.removeEventListener("mousemove", onMouseMove);
}

/**
 * After Swiper upgrade mouseUp events stopped propagating, so onClick listener has been added
 * @param {Event} event - the DOM click event
 */
function onClick(event) {
   trackClickEvent(event);
}

function trackClickEvent(event) {
   const trackableParent = event.target.closest(".js-afound-trackable");
   const dataParent = event.target.closest("[data-product]");
   const productData = dataParent ? getProductData(dataParent) : null;
   const data =
      productData != null && productData.view !== "department" ? productData : getElementData(trackableParent);
   const link = event.target.nodeName === "A" ? event.target : event.target.closest("a");
   data.targetUrl = link.pathname !== "" ? `${link.pathname}${link.search}` : null;

   const eventType = dataParent ? "productClick" : "click";

   schedulePendingEvents({
      type: eventType,
      data: data,
   });

   if (eventType == "click") {
      sessionStorage.setItem("af_promotion", data.id + "|" + data.name + "|" + data.creative);
   }

   link.removeEventListener("mouseup", onMouseUp);
   link.removeEventListener("mousedown", onMouseDown);
   link.removeEventListener("click", onClick);
}

/**
 * Parse the event data for gtm from the element
 * @param {HTMLElement} element
 */
function getElementData(element) {
   const metadata = JSON.parse(element.getAttribute("data-tracking-meta"));
   const position = Array.from(document.querySelectorAll(".js-afound-trackable")).indexOf(element);

   return {
      id: metadata.id,
      name: metadata.name,
      creative: metadata.creative,
      position: `${position}`,
      url: `${window.location.pathname}${window.location.search}`,
   };
}

/**
 * Parse the event data for gtm from the element
 * @param {HTMLElement} element
 */
function getProductData(element) {
   return JSON.parse(element.getAttribute("data-product"));
}

const pendingProductClickEvents = [];
const pendingClickEvents = [];
const pendingImpressionEvents = [];
let isRequestIdleCallbackScheduled = false;
function schedulePendingEvents(event) {
   if (event.type === "impression") {
      pendingImpressionEvents.push(event.data);
   } else if (event.type === "click") {
      pendingClickEvents.push(event.data);
   } else if (event.type === "productClick") {
      pendingProductClickEvents.push(event.data);
   }

   if (isRequestIdleCallbackScheduled) {
      return;
   }

   isRequestIdleCallbackScheduled = true;

   if ("requestIdleCallback" in window) {
      requestIdleCallback(processPendingAnalyticsEvents, { timeout: 350 });
   } else {
      processPendingAnalyticsEvents();
   }
}

/**
 * Push all pending events to GTM dataLayer
 */
function processPendingAnalyticsEvents() {
   isRequestIdleCallbackScheduled = false;

   if (pendingClickEvents.length > 0) {
      pendingClickEvents.splice(0, pendingClickEvents.length).forEach((event) => {
         new ClickEvent(event.id, event.name, event.creative, event.position, event.targetUrl).push();
      });
   }

   if (pendingProductClickEvents.length > 0) {
      pendingProductClickEvents.splice(0, pendingProductClickEvents.length).forEach((event) => {
         new ProductClickEvent(
            event.currencyCode,
            event.list,
            event.name,
            event.code,
            event.price,
            event.brandName,
            event.marker,
            event.percentOff,
            event.view
         ).push();
      });
   }

   if (pendingImpressionEvents.length > 0) {
      new ImpressionEvent(pendingImpressionEvents.splice(0, pendingImpressionEvents.length)).push();
   }
}

/**
 * A class for observing elements on the page
 */
export class PageObserver {
   initalize() {
      return ensureIntersectionObserverExists().then(() => {
         try {
            // Can't use additional parameters options with IntersectionObserver polyfill
            intersectionObserver = isUsingPolyfill
               ? new IntersectionObserver(onIntersectionChange)
               : new IntersectionObserver(onIntersectionChange, {
                    threshold: 0.75,
                 });
            appDispatcher.subscribe((action) => {
               switch (action.type) {
                  case PAGE_OBSERVE_IMPRESSION:
                     intersectionObserver.observe(action.data);
                     break;
                  case PAGE_OBSERVE_CLICK:
                     observeClickEvents(action.data);
                     break;
               }
            });
         } catch (error) {
            appInsights && appInsights.trackException(error);
         }
      });
   }
}
