import ko from 'knockout';
/**
 * @description To pass params from view to viewmodel, similar to KO component's params.
 * By default, target viewModel is current context, otherwise specify by 'target' param when use.
 * Useful in passing server rendered localization text to ViewModel for display.
 * 
 * @example 
 * <input type="text" data-bind="value: email, params: {requiredError: 'Error message rendered from server size'}" />
 * <script>
 *  const exampleVM = {
 *    email: ko.observable(),
 *    params: {}   
 *  };
 *  //params will be available at
 *  setTimeout(() => {
 *    console.log(exampleVM.params.requiredError); //display log 'Error message rendered from server size'
 *  });
 * 
 * </script>
 */

ko.bindingHandlers.params = {
  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    // This will be called when the binding is first applied to an element
    // Set up any initial state, event handlers, etc. here
    let params = ko.unwrap(valueAccessor());
    const target = params.target || bindingContext.$rawData;

    if (!target.params) {
      target.params = {};
    }

    Object.keys(params).forEach(function (key) {
      target.params[key] = params[key];
    });
  },
};
