const integerFormatterOptions = {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

const fractionFormatterOptions = {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const swedishFractionFormatter = new Intl.NumberFormat('sv-SE', fractionFormatterOptions);
const euroFractionFormatter = new Intl.NumberFormat('en-GB', fractionFormatterOptions);
const swedishIntegerFormatter = new Intl.NumberFormat('sv-SE', integerFormatterOptions);
const euroIntegerFormatter = new Intl.NumberFormat('en-GB', integerFormatterOptions);

export const displayWithCurrency = (price, currencyCode = '') => {
  currencyCode = Object.prototype.toString.call(currencyCode) === '[object Function]' ? currencyCode() : currencyCode;
  if (!currencyCode) {
    currencyCode = '';
  }
  price = price ? price : 0;
  const priceHasFractions = price % 1 !== 0;
  switch (currencyCode.toLowerCase()) {
    case 'eur':
      return '\u20AC' + (priceHasFractions ? euroFractionFormatter.format(price) : euroIntegerFormatter.format(price));
    case 'sek':
      return (priceHasFractions ? swedishFractionFormatter.format(price) : swedishIntegerFormatter.format(price)) + '\u00A0SEK';
    default:
      return price + ' ' + currencyCode;
  }
};

export const createComponentViewModel = (vmFactory, useInnerHtml) => ({
  createViewModel: (params, componentInfo) => {

    if (componentInfo.templateNodes.length) {
      try {
        const reduced = componentInfo.templateNodes.reduce(
          (str, node) => {
            if (useInnerHtml) {
              str += (node.tagName && node.innerHTML) ? node.innerHTML : '';
            } else {
              str += (node.tagName && node.innerText) ? node.innerText : '';
            }
            return str;
          }, '');
        params = JSON.parse(reduced);
      } catch (e) {
        // noop
      }
    }

    return vmFactory(params);
  },
});

export const iterableToArray = (iterable) => {
  if (Array.from) return Array.from(iterable);

  const array = [];
  for (let i = 0; i < iterable.length; i += 1) {
    array.push(iterable[i]);
  }
  return array;
};

export const getElement = (selector, parent = document) => {
  const elements = parent.querySelectorAll(selector);
  return elements.length > 1 ? iterableToArray(elements) : elements[0];
};

export const addEvent = (element, eventTypes, callback, options = false) => {
  const events = [...eventTypes];
  const listeners = events.reduce((accumulator, event) => {
    element.addEventListener(event, callback, options);
    return [
      ...accumulator,
      () => element.removeEventListener(event, callback, options),
    ];
  }, []);

  return {
    remove: () => listeners.forEach(remove => remove()),
  };
};

export const addCustomClickEventListenerWithCapturing = (rootElement, selector, handler) => {
  rootElement.addEventListener('click', function (e) {
    let targetElement = e.target;

    while (targetElement != null) {
      if (targetElement.matches(selector)) {
        e.preventDefault(e);
        handler(targetElement);
        return;
      }
      targetElement = targetElement.parentElement;
    }
  },
  true
  );
};

export const getMarkerText = (product) => {
  if (product.IsSoldOut) {
    return 'Sold out';
  } else if (product.IsFlashDeal) {
    return 'Flash deal';
  } else if (product.SuperDeal) {
    return 'Dream Deal';
  } else if (product.IsNewIn) {
    return 'New';
  } else if (product.FurtherReduced) {
    return 'Further Reduced';
  } else if (product.EditorsPick) {
    return 'Editors Pick';
  }
  return '';
};

export const isNull = (obj, type) => {
  if ((type != null && typeof (type) != 'undefined') &&
    (obj != null && typeof (obj) == type))
    return false;
  else if (obj != null && typeof (obj) != 'undefined')
    return false;

  return true;
};

export const isArray = (obj) => {
  return obj != null && obj.constructor === Array;
};

export const randomCharacters = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const getQueryStringValue = (key) => {
  // msdn

  return unescape(decodeURI(
    (window.location.search || window.location.hash).replace( // need to have fallback to hash, because we hash the querystring #?querystring
    /* eslint-disable */
      new RegExp('^(?:.*[&\\?]' + escape(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1')));
};

export const toQueryString = (obj) => {
  let parts = [];
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      parts.push(obj[i].key + '=' + encodeURIComponent(obj[i].value));
    }
  }
  return parts.join('&');
};

export const carousel = (len, i, steps = 1) => {
  if (i < 0) {
    return len - steps;
  } else if (i >= len) {
    return 0;
  } else {
    return i;
  }
};

export const deBounce = (func, wait = 40, immediate = true) => {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      !immediate && func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    callNow && func.apply(context, args);
  };
};

export const parseDateTimeString = (dateString) => {
  return dateString.includes('Date(') ? new Date(parseInt(dateString.substr(6))) : new Date(dateString);
};
/**
 * @desc This blocks all user interactions on screen. Use conserveratively only for critical non-interruptable actions such as Payment action.
 */

function blockUIHandler(e) {
  e.stopPropagation();
  e.preventDefault();
}
export const blockUI = () => {
  document.addEventListener('click', blockUIHandler, true);
  document.addEventListener('mousedown', blockUIHandler, true);
  document.addEventListener('keydown', blockUIHandler, true);
};
export const unblockUI = () => {
  document.removeEventListener('click', blockUIHandler, true);
  document.removeEventListener('mousedown', blockUIHandler, true);
  document.removeEventListener('keydown', blockUIHandler, true);
};

export function escapeXml(unsafe) {
  return unsafe.replace(/[<>&'"]/g, function (c) {
    switch (c) {
      case '<': return '&lt;';
      case '>': return '&gt;';
      case '&': return '&amp;';
      case '\'': return '&apos;';
      case '"': return '&quot;';
    }
  });
}

export const isMobileDevice = () => {
  return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
};

const clickableButton = document.getElementById('af-clickable');
if (clickableButton) {
  clickableButton.addEventListener('click', function (ev) {
    ev.stopPropagation();
  });
}

function addObserverIfDesiredNodeAvailable() {
  const targetNodes = document.getElementsByClassName('af-flickity-carousel');
  if (!targetNodes) {
    return;
  }

  // Options for the observer (which mutations to observe)
  const config = { attributes: true };

  // Callback function to execute when mutations are observed
  const callback = function (mutationsList, observer) {
    // Use traditional 'for loops' for IE 11
    for (const mutation of mutationsList) {

      if (mutation.type === 'attributes' && mutation.target.className.includes('flickity-enabled')) {
        const carouselList = document.getElementsByClassName('pwp-carousel');
        for (let i = 0; i < carouselList.length; i++) {
          carouselList[i].style.visibility = 'visible';
         }
      }
    }
    //stop observing
    observer.disconnect();

  };

  // Create an observer instance linked to the callback function


  // Start observing the target node for configured mutations

  for (let i = 0; i < targetNodes.length; i++) {
    const observer = new MutationObserver(callback);
    observer.observe(targetNodes[i], config);
  }

}
addObserverIfDesiredNodeAvailable();

 




