import ko from 'knockout';

const logError = (error, context, properties) => {
  try {
    if (typeof appInsights === 'undefined') {
      if (console) {
        // eslint-disable-next-line no-console
        console.warn(error, properties.node);
      }
      return;
    }

    if (context && properties) {
      appInsights.trackException(error, context, properties);
    } else {
      appInsights.trackException(error);
    }
  } catch (e) {
    // noop
  }
};

export function subscribeToKnockoutErrors() {
  (function () {
    let existing = ko.bindingProvider.instance;

    ko.bindingProvider.instance = {
      nodeHasBindings: existing.nodeHasBindings,
      getBindings: function (node, bindingContext) {
        let bindings;
        try {
          bindings = existing.getBindings(node, bindingContext);
        } catch (ex) {
          logError(ex, 'knockout',
            {
              node: node,
              bindingContext: bindingContext,
            });
        }
        return bindings;
      },
    };
  })();

  ko.onError = function (error) {
    logError(error);
  };
}
