import { validateDepartment } from '@afound/common';
import utils from "../../New/Common/utils";

const externalLoginRegisterModal = document.querySelector("#external-login-register-modal");
const externalLoginRegisterForm = document.querySelector("#external-login-register-form");
const newsLetterSignupCheckbox = document.querySelector('#external-login-register-modal [name="DirectMarketingPolicy"]');
const newsletterSignupDepartment = document.querySelector("#external-login-register-modal .login-page-modal__newsletter--department");
const newsletterSignupDepartmentError = document.querySelector(
   "#external-login-register-form .login-page-modal__newsletter--department .error"
);
const newsletterSignupDepartmentRadios = document.querySelectorAll(
   '#external-login-register-modal [name="DepartmentPreference"]'
);

const openModal = () => {
   externalLoginRegisterModal.classList.add("client-modal--is-open");   
}

utils.waitForElm("#external-login-register-modal").then((elm) => {
   openModal();
   newsLetterSignupCheckbox.addEventListener("change", (e) => {
      newsletterSignupDepartment.classList.toggle(
      "login-page-modal__newsletter--department-hide",
      !e.target.checked);    
    });

    newsletterSignupDepartment.addEventListener("change", (event) => {
      if (event.target.name === "DepartmentPreference") {
        validateDepartment(newsletterSignupDepartmentRadios, newsletterSignupDepartmentError, newsLetterSignupCheckbox.checked);
      }
    });

   externalLoginRegisterForm.addEventListener("submit", (e) => {  
      e.preventDefault(); 
      if (validateDepartment(newsletterSignupDepartmentRadios, newsletterSignupDepartmentError, newsLetterSignupCheckbox.checked)) {
         externalLoginRegisterForm.submit(); 
      }
   });
});
