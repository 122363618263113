import ko from 'knockout';
import appDispatcher from '../Common/appDispatcher';
import { observeImpression, observeClick } from '../Tracking/tracking-action-creators';

/**
 * Binding handler for tracking element on the page, e.g. impressions and clicks
 */
class TrackingHandler {
  update(element, valueAccessor) {
    appDispatcher.dispatch(observeImpression(element));
    appDispatcher.dispatch(observeClick(element));
  }
}

ko.bindingHandlers.tracking = new TrackingHandler();
