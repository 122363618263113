/* eslint-disable no-unused-vars */
import "../../Styles/master.scss";
import "./Common/data-collection";
import "es6-object-assign/auto";
import "./New/Register/RegisterPage.js";
import "./New/ExternalLogin/ExternalLoginDeclinedPermissions.js";
import "./New/ExternalLogin/ExternalLoginRegister.js";
import "./New/Login/LoginPage.js";
import "./New/ForgotPassword/ForgotPasswordPage.js";
import "./New/Tooltips/index.js";
import "./Features/Shared/productCardClick";

import * as ko from "knockout";

import {
   initialData,
   PAGE_SCROLL_BOTTOM,
   PAGE_SCROLL_TO_POS,
   PAGE_SCROLL_TOP,
   scrollToTop,
} from "./Common/action-creators";
import appDispatcher from "./Common/appDispatcher";
import esalesNotifier from "./Common/Esales/esalesNotifier";
import { displayWithCurrency } from "./Common/util";
import newsletterVM from "./Features/Newsletter/newsletterVM";
import modalVM from "./Features/Shared/modalVM";
import notifications from "./Features/Shared/notifications";
import trackWebVitals from "./Features/WebVitals/web-vitals";
import * as init from "./Initialization";
import { GTM_ACTION_CREATE, PageObserver } from "./Tracking";
import tabs from "./Features/Tabs/tabs";
import accordion from "./Features/Accordion/accordion";
import csatVM from "./Features/Csat/csatVM";

declare global {
   interface Window {
      GLOBAL: any;
   }
}

/**
 * Initialize the main AppVM - is dependent on async modules loaded in the loadAsyncModules function
 */
const initializeApp = (modules: any) => {
   init.subscribeToKnockoutErrors();
   return new Promise((resolve, reject) => {
      const appVM = {
         notifications: notifications(appDispatcher, INITIALDATA.notifications),
         productVM: INITIALDATA.product && modules["productVM"](appDispatcher, INITIALDATA.product),
         csatVM,
         productListing: INITIALDATA.listing && modules["productListing"](INITIALDATA.listing),
         modalVM,
         newsletterVM: newsletterVM(appDispatcher),
         scrollToTop: (o: any, e: any) => {
            appDispatcher.dispatch(scrollToTop());
            if (e && e.currentTarget) {
               e.currentTarget.blur();
            }
         },
         displayWithCurrency,
         dispatch: (action: any) => appDispatcher.dispatch(action),
         esalesNotifier: esalesNotifier(),
      };

      window.GLOBAL = appVM;

      appDispatcher.subscribe((action: any) => {
         switch (action.type) {
            // @future use native's scrollIntoView for smooth scroll by major browsers, ref: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
            case PAGE_SCROLL_TOP:
               window.scrollTo(0, 0);
               document.body.scrollTop = 0;
               break;
            case PAGE_SCROLL_BOTTOM:
               window.scrollTo(0, document.body.scrollHeight);
               document.body.scrollTop = document.body.scrollHeight;
               break;
            case GTM_ACTION_CREATE:
               if (action.data.gtm) {
                  dataLayer.push(action.data.gtm);
               }
               break;
            case PAGE_SCROLL_TO_POS:
               if (!action.data) return;
               window.scrollTo(0, action.data);
               document.body.scrollTop = action.data;
               break;
         }
      });

      appVM.esalesNotifier.startAutomaticListener();
      trackWebVitals();

      tabs.init();
      accordion.init();

      // global var that's set/enriched in view files
      appDispatcher.dispatch(initialData(INITIALDATA));

      // This must come after initial data dispatch
      ko.applyBindings(appVM);
   });
};

const pageObserverInit = new PageObserver().initalize();
const loadModules = init.loadModulesAsync();
Promise.all([pageObserverInit, loadModules])
   .then(([pageObserver, modules]) => {
      initializeApp(modules);
   })
   .catch((error) => {
      throw error;
   });
