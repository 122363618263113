import utils from '../Common/utils';
import {
  createGtmOnCreateAccount,
  createGtmOnSubscription,
  GTM_ACCOUNT_EVENT_TYPE_SUCCESS,
  GTM_ACCOUNT_EVENT_TYPE_ERROR,
  GTM_SIGNUP_EVENT_TYPE_SUCCESS,
  GTM_SUBSCRIPTION_EVENT_LABEL_ACCOUNT_REGISTER_PAGE,
} from '../../Tracking/gtm-action-creators';

/*
Register form
Form validation
*/

utils.waitForElm('#registerPageForm').then((registerPageForm) => {
   const registerPageEmail = document.getElementById('registerPageEmail');
   const registerPageEmailError = document.querySelector('#registerPageEmail + span.error');
   const registerPagePassword = document.getElementById('registerPagePassword');
   const registerPagePasswordError = document.querySelector('#registerPagePassword + span.error');
   const registerPageConfirmPassword = document.getElementById('registerPageConfirmPassword');
   const registerPageConfirmPasswordError = document.querySelector('#registerPageConfirmPassword + span.error');
   const registerPageServerError = document.querySelector('#registerPageForm span.server-error');
   const registerPageServerErrorMessage = document.querySelector('#registerPageForm span.server-error--text');
   const registerPageSubmitLoading = document.querySelector('#registerPageForm button[type="submit"]');
   const toggleNewsletterSignup = document.querySelector('#registerPageForm .register-page__content__top__newsletter');
   const toggleNewsletterSignupTarget = document.querySelector('#registerPageForm .register-page__content__top__newsletter .icon');
   const toggleNewsletterSignupDiv = document.querySelector('#registerPageForm .register-page__content__top__newsletter--checkbox');

   toggleNewsletterSignup.addEventListener('click', () => {
      toggleNewsletterSignupTarget.classList.toggle('p12icon-arrow-down');
      toggleNewsletterSignupTarget.classList.toggle('p12icon-arrow-up');
      toggleNewsletterSignupDiv.classList.toggle('register-page__content__top__newsletter--checkbox-hide');
   });

   registerPageEmail.addEventListener('input', (event) => {
      if (registerPageEmail.validity.valid) {
         registerPageEmailError.textContent = '';
         registerPageEmailError.className = 'error';
         registerPageEmail.className = 'form-control__input';
      }
   });

   registerPagePassword.addEventListener('input', (event) => {
      if (validatePassword(registerPagePassword, registerPagePasswordError, false)) {
         registerPagePasswordError.textContent = '';
         registerPagePasswordError.className = 'error';
         registerPagePassword.className = 'form-control__input';
      }
   });

   registerPageConfirmPassword.addEventListener('input', (event) => {
      if (registerPageConfirmPassword.validity.valid) {
         registerPageConfirmPasswordError.textContent = '';
         registerPageConfirmPasswordError.className = 'error';
         registerPageConfirmPassword.className = 'form-control__input';
      }
   });

   registerPageForm.addEventListener('submit', (event) => {
      event.preventDefault();

      if (validateEmail(registerPageEmail, registerPageEmailError)
         && validatePassword(registerPagePassword, registerPagePasswordError)
         && validatePasswordMissMatch(registerPagePassword, registerPageConfirmPassword, registerPageConfirmPasswordError)) {

         registerPageSubmitLoading.classList.add('btn--loading');

         const params = new URLSearchParams([...new FormData(event.target).entries()]);

         const departmentPreference = params.get('DepartmentPreference');
         
         let directMarketingPolicy = params.get('DirectMarketingPolicy');
         if (directMarketingPolicy) {
            params.append('SubscribeToNewsletter', 'true');
         }

         params.append('SubscribedFromFunction', GTM_SUBSCRIPTION_EVENT_LABEL_ACCOUNT_REGISTER_PAGE);

         fetch('/api/v1/authentication/registeraccount', { method: 'POST', body: params })
            .then((response) => response.json())
            .then((data) => {
               registerPageSubmitLoading.classList.remove('btn--loading');
               if (data.success) {
                  createGtmOnCreateAccount({ action: GTM_ACCOUNT_EVENT_TYPE_SUCCESS, message: GTM_SUBSCRIPTION_EVENT_LABEL_ACCOUNT_REGISTER_PAGE });

                  if (directMarketingPolicy && data.subscribedSuccessfully) {
                     createGtmOnSubscription({
                        action: GTM_SIGNUP_EVENT_TYPE_SUCCESS,
                        label: GTM_SUBSCRIPTION_EVENT_LABEL_ACCOUNT_REGISTER_PAGE,
                        departmentPreference: departmentPreference
                     });
                  }
                  let currentMarket = location.pathname.split("/")[1];
                  document.location.href = `/${currentMarket}`;
               } else {
                  createGtmOnCreateAccount({ action: GTM_ACCOUNT_EVENT_TYPE_ERROR, message: GTM_SUBSCRIPTION_EVENT_LABEL_ACCOUNT_REGISTER_PAGE });
                  registerPageServerErrorMessage.textContent = data.message;
                  registerPageServerError.className = 'server-error server-error--visible error-message spaced--medium';
               }
            });
      }
   });



});


/* Register END */

/* Validations */
function validateEmail(element, errorElement) {

  if (element.validity.valueMissing) {
    errorElement.textContent = INITIALDATA.validationMessages.required;
    errorElement.className = 'error form-control__error';
    element.className = 'form-control__input error';
    return false;
  }

  const [isEmailValid, transformed] = utils.validateTransformedEmail(element.value);
  element.value = transformed;

  if (!isEmailValid) {
    errorElement.textContent = INITIALDATA.validationMessages.email;
    errorElement.className = 'error form-control__error';
    element.className = 'form-control__input error';
    return false;
  }

  return true;
}

function validatePassword(element, errorElement, validateFormat = true) {
  let isValid = !element.validity.valueMissing;

  if (!isValid) {
    errorElement.textContent = INITIALDATA.validationMessages.required;
    errorElement.className = 'error form-control__error';
    element.className = 'form-control__input error';
  }

  if (!validateFormat) {
    return isValid;
  }

  isValid = utils.validatePassword(element.value);

  if (!isValid) {
    errorElement.className = 'error form-control__error';
    element.className = 'form-control__input error';
  }

  return isValid;
}

function validatePasswordMissMatch(element, elementMatch, errorElement) {

  if (element.value !== elementMatch.value) {
    errorElement.textContent = INITIALDATA.validationMessages.passwordMissMatch;
    errorElement.className = 'error form-control__error';
    elementMatch.className = 'form-control__input error';
    return false;
  }

  return true;
}
/* Validations END */
