import { getMarkerText } from '../Common/util';

// PDP
export const GTM_PDP_INITIAL_LOAD = 'GTM_PDP_INITIAL_LOAD';
export const GTM_PDP_INITIAL_LOAD_EVENT_TYPE = 'productDetailEvent';
export const GTM_PDP_ADD_TO_CART = 'GTM_PDP_ADD_TO_CART';
export const GTM_PDP_ADD_TO_CART_EVENT_TYPE = 'addToCartEvent';
export const GTM_PDP_CHANGE_SIZE = 'GTM_PDP_CHANGE_SIZE';
export const GTM_PDP_CHANGE_SIZE_CATEGORY = 'Change Size';
export const GTM_PDP_STATE = { addToCartEvent: [], buyBoxEvent: { size: [], seller: [] }, impressioins: [], detailEvent: [] };

export const createGtmOnProductClick = (product, index, list, currency, view) => {
  try {

    let marketText = getMarkerText(product);
    let gtmProduct = {
      'event': 'productClickEvent',
      'ecommerce': {
        'currencyCode': currency,
        'click': {
          'actionField': { 'list': list },
          'products': [
            {
              'name': product.Name,
              'id': product.Code,
              'price': product.DisplayPrice ? product.DisplayPrice.toString() : null,
              'brand': product.BrandName,
              'category': null,
              'variant': null,
              'dimension6': null,
              'dimension7': (marketText != '') ? marketText : null,
              'dimension8': null,
              'dimension1': product.PercentOff ? product.PercentOff.toString() : null,
              'dimension9': null,
              'dimension10': null,
              'dimension20': view,
            },
          ],
        },
      },
    };
    if (dataLayer) {
      dataLayer.push(gtmProduct);
    }

  } catch (e) {
    // noop
  }
};
export const createGtmOnSizeOrSellerChange = (data) => {
  try {
    if (dataLayer) {
      dataLayer.push({
        event: data.event,
        eventCategory: data.cateory,
        eventAction: data.action,
        eventLabel: data.label,
      });
    }
  } catch (e) {
    // noop
  }
};

// / impressions
export const GTM_IMPRESSION_EVENT_TYPE = 'impressionLoadEvent';


// / common dispatcher
export const GTM_ACTION_CREATE = 'GTM_ACTION_CREATE';
export const addToGTM = (data) =>
  (dispatch) => {
    dispatch({ type: GTM_ACTION_CREATE, data });
  };

// / send impression items which are in view port
export const sendImpressionItems = (data) => (dispatch) => {
  dispatch({ type: GTM_IMPRESSION_EVENT_TYPE, data });
};

// / viewModel is responsible to provide source data and subscribed ids
export const createGtmOnImpressions = (data) => {
  try {

    if (data.impressions && data.impressions.length >= 0) {

      let impressions = data.impressions.filter(promotion => {

        return data.ids.indexOf(promotion.dimension20 + '##' + promotion.id) !== -1;

      });


      if (impressions.length > 0 && dataLayer) {

        const productRecomendation = {

          event: GTM_IMPRESSION_EVENT_TYPE,

          'ecommerce': {

            currencyCode: data.currency,

            impressions,

          },

        };

        dataLayer.push(productRecomendation);

      }

    }

  } catch (e) {
    // noop
  }
};

// /login account
export const GTM_LOGIN_EVENT_TYPE = 'loginEvent';
export const GTM_LOGIN_EVENT_CATEGORY = 'login';
export const GTM_LOGIN_EVENT_TYPE_SUCCESS = 'login';
export const GTM_LOGIN_PAGE_EVENT_TYPE_SUCCESS = 'login:page';
export const GTM_LOGIN_EVENT_TYPE_ERROR = 'error:login';

export const createGtmOnLogin = (data) => {
  try {
    if (data && dataLayer) {
      dataLayer.push({
        event: GTM_LOGIN_EVENT_TYPE,
        eventCategory: GTM_LOGIN_EVENT_CATEGORY,
        eventAction: data.action,
        eventLabel: data.message,
      });
    }
  } catch (e) {
    // noop
  }
};

// /create account
export const GTM_ACCOUNT_EVENT_TYPE = 'customerEvent';
export const GTM_ACCOUNT_ACTIVATE_EVENT_TYPE = 'customerActivationEvent';

export const GTM_ACCOUNT_EVENT_CATEGORY = 'Account';
export const GTM_ACCOUNT_EVENT_TYPE_SUCCESS = 'Create account';
export const GTM_ACCOUNT_ACTIVATE_EVENT_TYPE_SUCCESS = 'Activate account';

export const GTM_ACCOUNT_EVENT_TYPE_ERROR = 'Account creation failed';

export const createGtmOnCreateAccount = (data) => {
  try {
    if (data && dataLayer) {
      dataLayer.push({
        event: GTM_ACCOUNT_EVENT_TYPE,
        eventCategory: GTM_ACCOUNT_EVENT_CATEGORY,
        eventAction: data.action,
        eventLabel: data.message,
      });
    }
  } catch (e) {
    // noop
  }
};

export const createGtmOnActivateAccount = (data) => {
  try {
    if (data && dataLayer) {
      dataLayer.push({
        event: GTM_ACCOUNT_ACTIVATE_EVENT_TYPE,
        eventCategory: GTM_ACCOUNT_EVENT_CATEGORY,
        eventAction: data.action,
        eventLabel: data.message,
      });
    }
  } catch (e) {
    // noop
  }
};

// /subscribe email
export const GTM_SIGNUP_EVENT_TYPE = 'newsletterSignupEvent';
export const GTM_SIGNUP_EVENT_CATEGORY = 'Email';
export const GTM_SIGNUP_EVENT_TYPE_SUCCESS = 'newslettersignup';
export const GTM_SIGNUP_EVENT_TYPE_ERROR = 'error:newslettersubscription';

export const GTM_SUBSCRIPTION_EVENT_LABEL_FOOTER = 'footer';
export const GTM_SUBSCRIPTION_EVENT_LABEL_NEWSLETTER = 'newsletter page';
export const GTM_SUBSCRIPTION_EVENT_LABEL_NEWSLETTER_PRESHOP = 'preshop page';
export const GTM_SUBSCRIPTION_EVENT_LABEL_ACCOUNT_REGISTER = 'registeraccount:modal';
export const GTM_SUBSCRIPTION_EVENT_LABEL_ACCOUNT_REGISTER_PAGE = 'registeraccount:page';
export const GTM_SUBSCRIPTION_EVENT_LABEL_CUSTOMER_BAR = 'customer bar';

export const createGtmOnSubscription = (data) => {
  try {
    if (data && dataLayer) {
      dataLayer.push({
        event: GTM_SIGNUP_EVENT_TYPE,
        eventCategory: GTM_SIGNUP_EVENT_CATEGORY,
        eventAction: data.action,
        eventLabel: data.label,
        departmentPreference: data.departmentPreference || '',
      });
    }
  } catch (e) {
    // noop
  }
};


// /search

export const GTM_SEARCH_EVENT_TYPE = 'customSearchEvent';
export const GTM_SEARCH_BY_TEXT_EVENT_TYPE = 'customSearchByTextEvent';
export const GTM_SEARCH_EVENT_CATEGORY = 'Search';

export const createGtmOnSearch = (data) => {
  try {
    if (data && dataLayer) {
      dataLayer.push({
        event: GTM_SEARCH_EVENT_TYPE,
        eventCategory: GTM_SEARCH_EVENT_CATEGORY,
        eventLabel: data.url,
      });
    }
  } catch (e) {
    // noop
  }
};

export const createGtmOnSearchByText = (data) => {
   try {
      if (data && dataLayer) {
         dataLayer.push({
            event: GTM_SEARCH_BY_TEXT_EVENT_TYPE,
            eventCategory: GTM_SEARCH_EVENT_CATEGORY,
            eventAction: data.query,
            eventLabel: data.url,
         });
      }
   } catch (e) {
      // noop
   }
};

export const GTM_GADS_VIEW_ITEM_LIST_EVENT = 'view_item_list';
export const GTM_GADS_VIEW_SEARCH_RESULTS_EVENT = 'view_search_results';

export const createGtmGadsEvent = (eventName, data) => {
  try {
    const gtm = {      
      event: eventName,
      value: data.value,
      items: data.items,
    };
    if (dataLayer) {
      dataLayer.push(gtm);
    }
  } catch (e) {
    // noop
  }
};


const GTM_FILTER_AND_SORTING_EVENT = 'filterAndSortingEvent';
const GTM_FILTER_AND_SORTING_EVENT_CATEGORY = 'filter&sorting';
export const GTM_FILTER_AND_SORTING_EVENT_ACTION_SORTING = 'sorting';
export const GTM_FILTER_AND_SORTING_EVENT_ACTION_FILTER = 'filter';

export const createGtmFilterAndSortingEvent = (data) => {
   try {
      let gtm = {
         event: GTM_FILTER_AND_SORTING_EVENT,
         eventCategory: GTM_FILTER_AND_SORTING_EVENT_CATEGORY,
         eventAction: data.action,
         eventLabel: data.label,
      };
      if (dataLayer) {
         dataLayer.push(gtm);
      }
   } catch (e) {
      // noop
   }
};

export const GTM_NLG_EVENT = 'nlgPageEvent';
export const GTM_NLG_EVENT_CATEGORY = 'NLG page view';
export const GTM_NLG_EVENT_ACTION = 'NLG page visited';

export const createGtmNlgEvent = (data) => {
  try {
    const gtm = {      
      event: GTM_NLG_EVENT,
      eventCategory: GTM_NLG_EVENT_CATEGORY,
      eventAction: GTM_NLG_EVENT_ACTION,
      label: data.label,
    };
    if (dataLayer) {
      dataLayer.push(gtm);
    }
  } catch (e) {
    // noop
  }
};

export const GTM_CONTACTUS_SUBMIT_EVENT = 'formEvent';
export const GTM_CONTACTUS_SUBMIT_EVENT_CATEGORY = 'Form';
export const GTM_CONTACTUS_SUBMIT_EVENT_ACTION = 'Contact us';
export const GTM_CONTACTUS_SUBMIT_EVENT_TYPE_SUCCESS = 'Contact us:sent';
export const GTM_CONTACTUS_SUBMIT_EVENT_TYPE_ERROR = 'Contact us:error';

export const createGtmOnContactUsEvent = (data) => {
  try {
    let gtm = {
      event: GTM_CONTACTUS_SUBMIT_EVENT,
      eventCategory: GTM_CONTACTUS_SUBMIT_EVENT_CATEGORY,
      eventAction: GTM_CONTACTUS_SUBMIT_EVENT_ACTION,
      eventLabel: data.label,
    };
    if (dataLayer) {
      dataLayer.push(gtm);
    }
  } catch (e) {
    // noop
  }
};

// CustomerSegmentPopup
export const GTM_CustomerSegmentPopup_EVENT_TYPE = 'customerSegmentPopupEvent';
export const GTM_CustomerSegmentPopup_EVENT_CATEGORY = 'customerSegmentPopup';

export const createGtmOnCustomerSegmentPopup = (data) => {
   try {
      if (data && dataLayer) {
         dataLayer.push({
            event: GTM_CustomerSegmentPopup_EVENT_TYPE,
            eventCategory: GTM_CustomerSegmentPopup_EVENT_CATEGORY,
            eventAction: data.action,
            eventLabel: data.label,
         });
      }
   } catch (e) {
      // noop
   }
};

const GTM_Accordion_EVENT_TYPE = 'pdpEvent';
const GTM_Accordion_EVENT_CATEGORY = 'PDP';
const GTM_Accordion_EVENT_LABEL = 'Open';

export const createGtmAccordionData = (data) => {
   try {
      if (data && dataLayer) {
         dataLayer.push({
            event: GTM_Accordion_EVENT_TYPE,
            eventCategory: GTM_Accordion_EVENT_CATEGORY,
            eventAction: data.action,
            eventLabel: GTM_Accordion_EVENT_LABEL,
         });
      }
   } catch (e) {
      // noop
   }
};

const GTM_Size_EVENT_TYPE = 'pdpEvent';
const GTM_Size_EVENT_CATEGORY = 'PDP';
const GTM_Size_EVENT_Label = 'Changed size';

export const createGtmSizeData = (data) => {
   try {
      if (data && dataLayer) {
         dataLayer.push({
            event: GTM_Size_EVENT_TYPE,
            eventCategory: GTM_Size_EVENT_CATEGORY,
            eventAction: data.action,
            eventLabel: GTM_Size_EVENT_Label,
         });
      }
   } catch (e) {
      // noop
   }
};