const accordion = () => {
  const handleAccordionClick = (accordionToggle) => {
    const accordionContainer = accordionToggle.closest('[data-js-selector="accordion-container"]');
    const selected = accordionContainer.getAttribute('data-js-preselect');
    const isMultiple = !accordionContainer || (accordionContainer && accordionContainer.classList.contains('af-accordion--multiple'));
    const item = accordionToggle.closest('[data-js-selector="accordion-item"]');

    accordionToggle.addEventListener('click', function () {
      if (!isMultiple && !item.classList.contains('is-expanded')) {
        const active = accordionContainer.querySelectorAll('[data-js-selector="accordion-item"].is-expanded');
        for (let i = 0; i < active.length; i++) {
          active[i].classList.remove('is-expanded');
        }
      }
      item.classList.toggle('is-expanded');
    });

    if (selected && item.classList.contains(selected)) {
      item.classList.add('is-expanded');
    }
  };

  const init = () => {
    document.querySelectorAll('[data-js-selector="accordion-toggle"]').forEach(acc => handleAccordionClick(acc));
  };

  return {
    init,
  };

};

export default accordion();
