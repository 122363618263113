export const handleProductClick = (els: NodeListOf<HTMLElement>) => {
   els.forEach((el) => {
      el.addEventListener("click", (ev: MouseEvent) => {
         const target = ev.target as HTMLElement;
         const priceBlock = target.closest(".price-info-table");
         if (priceBlock) {
            ev.preventDefault();
         } else {
            const trackableTarget = target.closest("a") as HTMLElement;
            if (trackableTarget) {
               Afound.data_collection.gtm.ecommerce.send_product_click(trackableTarget);
            }
         }
      });
   });
};

const productCards: NodeListOf<HTMLElement> = document.querySelectorAll(".ref-product-card-click");

if (productCards) {
   handleProductClick(productCards);
}
