import utils from '../Common/utils';

/*
Forgot password form
Form validation
*/

utils.waitForElm('#forgotPasswordPageForm').then((forgotPasswordPageForm) => {

   const forgotPasswordEmail = document.getElementById('forgotPasswordPageEmail');
   const forgotPasswordEmailError = document.querySelector('#forgotPasswordPageEmail + span.error');
   const forgotPasswordServerContainer = document.querySelector('#forgotPasswordPageForm span.server-message');
   const forgotPasswordServerMessage = document.querySelector('#forgotPasswordPageForm span.server-message--text');
   const forgotPasswordSubmitLoading = document.querySelector('#forgotPasswordPageForm button[type="submit"]');

      forgotPasswordEmail.addEventListener('input', (event) => {

         if (forgotPasswordEmail.validity.valid) {
            forgotPasswordEmailError.textContent = '';
            forgotPasswordEmailError.className = 'error';
            forgotPasswordEmail.className = 'form-control__input';
         }
      });


         forgotPasswordPageForm.addEventListener('submit', (event) => {
         event.preventDefault();

         if (!forgotPasswordEmail.validity.valid) {
            validateForgotEmail(forgotPasswordEmail, forgotPasswordEmailError);
         } else {
            forgotPasswordSubmitLoading.classList.add('btn--loading');

            let data = JSON.stringify({ 'Email': forgotPasswordEmail.value });
            fetch('/api/v1/authentication/forgotpassword', {
               method: 'POST',
               headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
               },
               body: data,
            })
               .then((response) => response.json())
               .then((data) => {
                  forgotPasswordSubmitLoading.classList.remove('btn--loading');

                  if (data.success) {
                     forgotPasswordServerMessage.textContent = data.message;
                     forgotPasswordServerContainer.className = 'server-message server-message--visible success-message spaced--medium';
                  }
               });
         }
      }); 
});


/* Forgot Password END */

/* Validations */
function validateForgotEmail(element, errorElement) {

  if (element.validity.valueMissing) {
    errorElement.textContent = INITIALDATA.validationMessages.required;
    errorElement.className = 'error form-control__error';
    element.className = 'form-control__input error';
    return false;
  }

  const isEmailValid = utils.validateEmail(element.value);

  if (!isEmailValid) {
    errorElement.textContent = INITIALDATA.validationMessages.email;
    errorElement.className = 'error form-control__error';
    element.className = 'form-control__input error';
    return false;
  }

  return true;
}
/* Validations END */
