import ko from "knockout";
// HACK: custom flickity because it is also used by react,
// and flickity tries to initialize twice. Can probably be reverted if we fix/remove the react component.
import Flickity from "../Vendor/flickity.custom";
import Swiper from "swiper/bundle";

const insertArrow = (arg, arrowName) => arg && { arrowShape: { ...arrowName } };

const defaultSettings = {
   draggable: true, // Changes height of carousel to fit height of selected slide.
   adaptiveHeight: false, // Changes height of carousel to fit height of selected slide. [FALSE]
   accessibility: true, // Enable keyboard navigation. Users can tab to a Flickity carousel, and pressing left & right keys to change cells.
   setGallerySize: false, // Tells flickity that CSS controls height
   autoPlay: false,
};

/**
 * Standard Carousel Factory
 */
const standardCarousel = (
   infinity = true,
   arrows = true,
   pagination = false,
   customArrow = false,
   customArrowName = arrows.chevron // default to chevron
) => {
   let standardCarouselSettings = {
      ...defaultSettings,
      wrapAround: infinity, // Infinity
      cellAlign: "center",
      prevNextButtons: arrows,
      pageDots: pagination,
      ...insertArrow(customArrow, customArrowName),
   };
   return standardCarouselSettings;
};

// ----------------------------------------------------------------------- //
// bindinghandler                                                          //
// ----------------------------------------------------------------------- //
ko.bindingHandlers.carousel = {
   update: (el, valueAccessor) => {
      const val = valueAccessor();

      let carouselType;

      switch (val) {
         case "product-card":
            carouselType = standardCarousel(false, false, true, false, false);
            break;
         default:
      }

      new Flickity(el, carouselType);
   },
};

/**
 * NOTE: The way of initializing Swiper(s) below is OBSOLETE and should not be further built on. Instead, configure
 * each Swiper instance in a feature module belonging to the feature.
 */

const isHandledInFeatureModule = (swiper) => {
   const classes = [
      "swiper--inspirational-carousel",
      "swiper--inspirational-carousel-3-images-layout",
      "swiper--new-brand-page",
   ];

   return classes.some((c) => swiper.classList.contains(c));
};

if (typeof swipers === "undefined") {
   let swipers = document.getElementsByClassName("swiper");
   let swipersWithThumbnails = document.getElementsByClassName("swiper-container");

   for (let i = 0; i < swipers.length; i++) {
      // Exclude the following Swipers which are handled in feature modules instead
      if (isHandledInFeatureModule(swipers[i])) {
         continue;
      }

      let swiperId = `swiper-${i}`;
      swipers[i].classList.add("af-initiated");
      swipers[i].classList.add(swiperId);

      let swiperProperties = {
         navigation: {
            nextEl: `.${swiperId} .swiper-button-next`,
            prevEl: `.${swiperId} .swiper-button-prev`,
         },
         preloadImages: false,
         slidesPerView: "auto",
         paginationClickable: true,
         spaceBetween: 2,
         passiveListeners: true,
         freeMode: true,
         touchStartForcePreventDefault: true,
         lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 10,
            loadOnTransitionStart: true,
         },
         scrollbar: {
            el: `.${swiperId} .swiper-scrollbar`,
            draggable: true,
            snapOnRelease: true,
         },
      };

      if (swipers[i].classList.contains("swiper--product-page")) {
         swiperProperties = {
            slidesPerView: 4,
            spaceBetween: 30,
            slidesPerGroup: 4,
            preloadImages: false,
            lazy: {
               checkInView: true,
               loadPrevNext: true,
               loadOnTransitionStart: true,
               loadPrevNextAmount: 1,
            },
            pagination: {
               el: `.${swiperId} .swiper-pagination`,
               clickable: true,
            },
            navigation: {
               nextEl: `.${swiperId} .swiper-button-next`,
               prevEl: `.${swiperId} .swiper-button-prev`,
            },
            breakpoints: {
               "@0.00": {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  spaceBetween: 10,
               },
               "@0.75": {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                  spaceBetween: 20,
               },
               "@1.00": {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                  spaceBetween: 20,
               },
            },
         };
      }

      if (
         swipers[i].classList.contains("swiper--top-categories") ||
         swipers[i].classList.contains("swiper--cross-department-top-categories") ||
         swipers[i].classList.contains("swiper--recently-viewed")
      ) {
         let slidesPerView = 3;
         let slidesPerGroup = 3;

         if (swipers[i].classList.contains("swiper--recently-viewed")) {
            slidesPerView = 2;
            slidesPerGroup = 2;
         }

         swiperProperties = {
            slidesPerView: 4,
            spaceBetween: 30,
            slidesPerGroup: 4,
            preloadImages: false,
            lazy: {
               checkInView: true,
               loadPrevNext: true,
               loadOnTransitionStart: true,
               loadPrevNextAmount: 1,
            },
            pagination: {
               el: `.${swiperId} .swiper-pagination`,
               clickable: true,
            },
            navigation: {
               nextEl: `.${swiperId} .swiper-button-next`,
               prevEl: `.${swiperId} .swiper-button-prev`,
            },
            breakpoints: {
               "@0.00": {
                  slidesPerView,
                  slidesPerGroup,
                  spaceBetween: 10,
               },
               "@0.75": {
                  slidesPerView,
                  slidesPerGroup,
                  spaceBetween: 20,
               },
               "@1.00": {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                  spaceBetween: 20,
               },
            },
         };
      }

      /* eslint-disable no-unused-vars */
      let swiper = new Swiper(`.${swiperId}`, swiperProperties);
   }

   for (let i = 0; i < swipersWithThumbnails.length; i++) {
      if (swipersWithThumbnails[i].classList.contains("gallery-main")) {
         let swiperId = `swiper-${i}`;
         swipersWithThumbnails[i].classList.add("af-initiated");
         swipersWithThumbnails[i].classList.add(swiperId);

         let swiperThumbnails = document.querySelector(".gallery-thumbs");

         swiperThumbnails.classList.add("af-initiated");
         swiperThumbnails.classList.add(swiperId);

         let galleryThumbs = new Swiper(".gallery-thumbs", {
            slidesPerView: "auto",
            watchOverflow: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preloadImages: true,
            direction: "vertical",
            threshold: 100,
         });

         let galleryMain = new Swiper(".gallery-main", {
            navigation: {
               nextEl: `.${swiperId} .swiper-button-next`,
               prevEl: `.${swiperId} .swiper-button-prev`,
            },
            effect: "fade",
            fadeEffect: {
               crossFade: true,
            },
            thumbs: {
               swiper: galleryThumbs,
            },
            loop: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            preloadImages: true,
            pagination: {
               el: `.${swiperId} .swiper-pagination`,
               type: "fraction",
            },
         });
      }
   }
}

/* Swiper fullscreen mode for PDP */
let fullscreenImage = document.querySelector(".swiper-fullscreen");
let closeFullscreen = document.querySelector(".swiper-button-close");

if (closeFullscreen) {
   closeFullscreen.addEventListener("click", () => {
      fullscreenImage.classList.toggle("swiper-fullscreen-mode");
      document.body.style.overflow = "scroll";
   });
}

document.querySelectorAll("#pdp-product-page .swiper-fullscreen img").forEach((element) => {
   element.addEventListener("click", () => {
      closeFullscreen.classList.add("swiper-button-close--open");
      fullscreenImage.classList.toggle("swiper-fullscreen-mode");

      if (fullscreenImage.classList.contains("swiper-fullscreen-mode")) {
         document.body.style.overflow = "hidden";
         document.querySelectorAll(".product-page__grid-col-main picture").forEach((element) => {
            element.style.display = "none";
         });

         document.querySelectorAll(".product-page__grid-col-main .swiper-image-zoom").forEach((element) => {
            element.style.display = "block";
         });

         document.querySelectorAll(".product-page__grid-col-main .swiper-image-zoom img").forEach((element) => {
            if (!element.hasAttribute("src")) {
               element.setAttribute("src", element.getAttribute("data-src"));
            }
         });

         document.querySelectorAll(".product-page__grid-col-main .swiper-image-zoom source").forEach((element) => {
            if (!element.hasAttribute("srcset")) {
               element.setAttribute("srcset", element.getAttribute("data-srcset"));
            }
         });
      } else {
         document.body.style.overflow = "scroll";
         document.querySelectorAll(".product-page__grid-col-main picture").forEach((element) => {
            element.style.display = "block";
         });

         document.querySelectorAll(".product-page__grid-col-main .swiper-image-zoom").forEach((element) => {
            element.style.display = "none";
         });
      }
   });
});

document.querySelectorAll(".new-product-page .swiper-fullscreen .gallery-main img").forEach((element) => {
   element.addEventListener("click", () => {
      closeFullscreen.classList.add("swiper-button-close--open");
      fullscreenImage.classList.toggle("swiper-fullscreen-mode");

      if (fullscreenImage.classList.contains("swiper-fullscreen-mode")) {
         document.body.style.overflow = "hidden";
         document.querySelectorAll(".new-product-page .gallery-main picture").forEach((element) => {
            element.style.display = "none";
         });

         document.querySelectorAll(".new-product-page .gallery-main .swiper-image-zoom").forEach((element) => {
            element.style.display = "block";
         });

         document.querySelectorAll(".new-product-page .gallery-main .swiper-image-zoom img").forEach((element) => {
            if (!element.hasAttribute("src")) {
               element.setAttribute("src", element.getAttribute("data-src"));
            }
         });

         document.querySelectorAll(".new-product-page .gallery-main .swiper-image-zoom source").forEach((element) => {
            if (!element.hasAttribute("srcset")) {
               element.setAttribute("srcset", element.getAttribute("data-srcset"));
            }
         });
      } else {
         document.body.style.overflow = "scroll";
         document.querySelectorAll(".new-product-page .gallery-main picture").forEach((element) => {
            element.style.display = "block";
         });

         document.querySelectorAll(".new-product-page .gallery-main .swiper-image-zoom").forEach((element) => {
            element.style.display = "none";
         });
      }
   });
});
