
export default {
  Esales: {
    AddToCartClassPrefix: 'eS-addingToCart eS-t-',
    ClickClassPrefix: 'eS-click eS-t-',
    ClickClass: 'eS-click',
    AddToCartClass: 'eS-addingToCart',
    AddToWishlistClass: 'eS-addingToWishlist',
    PanelClass: 'es-panel-content',
  },
};
