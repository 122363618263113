const removeToolstips = () => {
   let removeActiveTooltip = document.querySelector(".tooltip.active");
   if (removeActiveTooltip) {
      removeActiveTooltip.classList.toggle('active');
   }
}
export const setTooltips = (tooltips) => {
   tooltips.forEach(tooltip => {
      tooltip.addEventListener('click', function () {
         let tooltipButton = this;
         let tooltipArea = tooltipButton.querySelector('div');
         
         if (tooltipArea.classList.contains('tooltip-pdp')) {
            //4 is to mitigate paddings etc
            let marginTop = tooltipArea.offsetHeight / 2 - document.querySelector('.tooltip-icon').offsetHeight / 2 - 4;
            tooltipArea.style.top = `-${marginTop}px`;
         } else {
            //20 is to mitigate paddings etc
            let marginTop = tooltipArea.offsetHeight - document.querySelector('.tooltip-icon').offsetHeight - 20;
            tooltipArea.style.top = `-${marginTop}px`;
         }

         if (this.classList.contains('active')) {
            this.classList.remove('active');
            removeToolstips();
         } else {
            removeToolstips();
            this.classList.add('active');
         }
      });
   });
}
export const showTooltip = (tooltip) => {
   let tooltipArea = tooltip.querySelector('div');
   //7 is to mitigate paddings etc
   let marginTop = tooltipArea.offsetHeight / 2 - document.querySelector('.tooltip-icon').offsetHeight / 2 - 7;
   tooltipArea.style.top = `-${marginTop}px`;

   if (tooltip.classList.contains('active')) {
      tooltip.classList.remove('active');
      removeToolstips();
   } else {
      removeToolstips();
      tooltip.classList.add('active');
   }
}
const tooltips = document.querySelectorAll('.tooltip');
setTooltips(tooltips);
