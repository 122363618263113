import utils from '../Common/utils';
import {
   createGtmOnLogin,
   GTM_LOGIN_EVENT_TYPE_SUCCESS,
   GTM_LOGIN_EVENT_TYPE_ERROR,
   GTM_LOGIN_PAGE_EVENT_TYPE_SUCCESS,
} from '../../Tracking/gtm-action-creators';

/*
Login page form
Form validation
*/

utils.waitForElm('#loginPageform').then((loginForm) => {
   const email = document.getElementById('loginPageEmail');
   const emailError = document.querySelector('#loginPageEmail + span.error');
   const password = document.getElementById('loginPagePassword');
   const passwordError = document.querySelector('#loginPagePassword + span.error');
   const loginServerError = document.querySelector('#loginPageform span.server-error');
   const loginServerErrorMessage = document.querySelector('#loginPageform span.server-error--text');
   const loginSubmitLoading = document.querySelector('#loginPageform button[type="submit"]');

   email.addEventListener('input', (event) => {

      if (email.validity.valid) {
         emailError.textContent = '';
         emailError.className = 'error';
         email.className = 'form-control__input';
      }
   });

   password.addEventListener('input', (event) => {
      if (password.validity.valid) {
         passwordError.textContent = '';
         passwordError.className = 'error';
         password.className = 'form-control__input';
      }
   });

   loginForm.addEventListener('submit', (event) => {
      event.preventDefault();
      if (!email.validity.valid) {
         validateLoginPageEmail(email, emailError);
      } else if (!password.validity.valid) {
         validateLoginPagePassword(password, passwordError);
      } else {
         loginSubmitLoading.classList.add('btn--loading');
         const params = new URLSearchParams([...new FormData(event.target).entries()]);
         fetch('/api/v1/authentication/login', { method: 'POST', body: params })
            .then((response) => response.json())
            .then((data) => {
               loginSubmitLoading.classList.remove('btn--loading');
               if (data.success) {
                  createGtmOnLogin({ action: GTM_LOGIN_EVENT_TYPE_SUCCESS, message: GTM_LOGIN_PAGE_EVENT_TYPE_SUCCESS })
                  if (data.returnUrl) {
                     location.href = data.returnUrl;
                  }
               } else {
                  createGtmOnLogin({ action: GTM_LOGIN_EVENT_TYPE_ERROR, message: data.failureReason })
                  loginServerErrorMessage.textContent = data.message;
                  loginServerError.className = 'server-error server-error--visible error-message spaced--medium';
               }
            });
      }
   });


});
/* Login Page END */

/* Validations */
function validateLoginPageEmail(element, errorElement) {

   if (element.validity.valueMissing) {
      errorElement.textContent = INITIALDATA.validationMessages.required;
      errorElement.className = 'error form-control__error';
      element.className = 'form-control__input error';
      return false;
   }

   const isEmailValid = utils.validateEmail(element.value);

   if (!isEmailValid) {
      errorElement.textContent = INITIALDATA.validationMessages.email;
      errorElement.className = 'error form-control__error';
      element.className = 'form-control__input error';
      return false;
   }

   return true;
}

function validateLoginPagePassword(element, errorElement) {

   if (element.validity.valueMissing) {
      errorElement.textContent = INITIALDATA.validationMessages.required;
      errorElement.className = 'error form-control__error';
      element.className = 'form-control__input error';
      return false;
   }

   const isPasswordValid = utils.validatePassword(element.value);

   if (!isPasswordValid) {
      errorElement.textContent = INITIALDATA.validationMessages.password;
      errorElement.className = 'error form-control__error';
      element.className = 'form-control__input error';
      return false;
   }

   return true;
}
/* Validations END */
