const waitForElm = (selector) => {
   return new Promise((resolve) => {
      if (document.querySelector(selector)) {
         return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
         if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
         }
      });

      observer.observe(document.body, {
         childList: true,
         subtree: true,
      });
   });
};

const validateEmail = (email) => {
   const re =
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/; // eslint-disable-line
   return re.test(email);
};

const validateTransformedEmail = (email) => {
   if (!email) {
      return [false, email];
   }

   const transformed = email.trim().toLowerCase();
   return [validateEmail(transformed), transformed];
};

const validatePassword = (password) => {
   const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/; // eslint-disable-line
   return re.test(password);
};

const getCookie = (cName) => {
   const name = cName + "=";
   const cDecoded = decodeURIComponent(document.cookie);
   const cArr = cDecoded.split('; ');
   let res;
   cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
   })
   return res;
}

const setCookie = (cName, cValue, expDays) => {
   let date = new Date();
   date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
   const expires = "expires=" + date.toUTCString();
   document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

const copyToClipboard = async (text) => {
   try {
      await navigator.clipboard.writeText(text);
   } catch (err) {
      //We use this to avoid crashing scripts
   }
}

export default {
   waitForElm,
   validateEmail,
   validateTransformedEmail,
   validatePassword,
   getCookie,
   setCookie,
   copyToClipboard,
};
