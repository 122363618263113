import * as ko from 'knockout';
const subscribers = [];

const dispatcher = {
  dispatch: (action) => {
    if (Object.prototype.toString.call(action) === '[object Function]') {
      action(dispatcher.dispatch);
    } else {
      subscribers.forEach(s => s(action));
    }
  },
  subscribe: (handler) => {
    subscribers.push(handler);
  },
  createStore: (reducer) => {
    let prevState = reducer(undefined, { type: '__INITIAL__' });
    const observable = ko.observable(prevState);
    subscribers.push((action) => {
      const nextState = reducer(prevState, action);
      if (nextState !== prevState) {
        observable(nextState);
        prevState = nextState;
      }
    });
    return observable;
  },
};

window.$dispatcher = dispatcher;  // conflict with raygun dispatcher

export default dispatcher;
