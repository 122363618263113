import ko from 'knockout';

const NOTIFICATION_ADD = 'NOTIFICATION_ADD';

export const addNotification = (notification) => ({
  type: NOTIFICATION_ADD,
  ...notification,
});

const pushTo = (vm, notification) => {
  const group = notification.group && vm.groups().filter(g => g.group === notification.group)[0];
  if (group) {
    group.text(notification.text);
  } else {
    vm.groups.push({
      ...notification,
      sticky: notification.sticky || false,
      renderAsMarkup: notification.renderAsMarkup || false,
      text: ko.observable(notification.text)
        .extend({
          notify: 'always',
        }),
    });
  }
};

// Fixes weird bug where åäö is shown with iso-5589-1 encoding
const htmlDecode = (input, renderAsMarkup) => {
  let e = document.createElement('div');
  e.innerHTML = input;
  if (!renderAsMarkup) {
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  } else {
    return e.innerHTML;
  }
};

// Pretty darn singletonish right now
export default (dispatcher, initialNotifications) => {
  const vm = {
    groups: ko.observableArray(),
    dismiss: g => vm.groups.remove(g),
    afterRender: items => {
      setTimeout(() => {
        items[1].classList.add('is-visible');
      }, 10); // Let it appear before we apply the animation
    },
    beforeRemove: item => {
      if (item.className && item.className.indexOf('notification') >= 0) {
        item.classList.remove('is-visible');
        setTimeout(() => item.parentNode.removeChild(item), 600); // Based on animation speed
      } else {
        item.parentNode.removeChild(item);
      }
    },
  };

  (initialNotifications || []).forEach(
    n => pushTo(vm, {
      text: htmlDecode(n),
      sticky: true,
    }));

  dispatcher.subscribe(a => {
    switch (a.type) {
      case NOTIFICATION_ADD:
        a.text = htmlDecode(a.text, a.renderAsMarkup);
        pushTo(vm, a);
        break;
    }
  });

  return vm;
};
