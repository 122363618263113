/* eslint-disable no-unused-vars */
/**
 * @desc
 * Extended KO's observerables for value (usually input fields) validation purpose.
 * There are default validation messages specified in validation.localizations.js (add more localization when needed).
 * Custom invalid messages can be specificed in view (HTML) or upon initiating observerable, see exmaple below.
 * 
 * @reference
 * https://knockoutjs.com/documentation/extenders.html
 * Live Example 2: Adding validation to an observable
 * 
 * @example
 * ko.observable().extend({required: true}); 
 * ko.observable().extend({required: true, email: true}); //ORDER MATTERS! rule comes first is of higher priority, e.g. check required before check
 * ko.observable().extend({email: 'You must provide your email'});
 * ko.observable().extend({phone: { countryCode: 'se', message: 'custom error message goes here'} });
 * ko.observable().extend({minLength: 6});
 * 
 * //example: to load custom message from VIEW, use custom binding handler 'params'
 * <input type="text" data-bind="value: email, params: {email: 'sever-rendered message goes here'}"/>
 */
import ko from 'knockout';
import { params } from '../bindinghandlers/params'; // to support laoding localized error message from View

const validationMessages = INITIALDATA?.validationMessages;

export const validationRules = {
  validateEmail: (email) => {
    const re = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/; // eslint-disable-line
    return re.test(email);
  },

  validatePassword: (password) => {
    const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/; // eslint-disable-line
    return re.test(password);
  },
};

/**
 * @desc Validate a series of fields in the order they are passed in.
 * @param {*} targetFields - A KO observable that is extended with below validator extenders. 
 * @return true if no errors
 * @return false if there are errors
 */
export const validateFormFields = (targetFields = []) => {
  return new Promise((resolve) => {

    if (!Array.isArray(targetFields)) {
      throw ('input has to be array');
    }
    let hasFirstError = false;

    const validationPromises = [];
    const results = [];

    /** Trigger all validation promises */
    for (let i = 0; i < targetFields.length; i++) {
      validationPromises.push(targetFields[i]
        .validate()
        .then(result => {
          results.push(result);
        }));
    }

    Promise
      .all(validationPromises)
      .then(() => {
        for (let i = 0; i < results.length; i++) {
          if (!results[i] && !hasFirstError) {
            targetFields[i].hasErrorFocus(true); // focus only on first error
            hasFirstError = true;
          }
        }
        (hasFirstError) ? resolve(false) : resolve(true);
      });
  });
};

export const clearFormErrors = (targetFields = []) => {
  if (!Array.isArray(targetFields)) {
    throw ('input has to be array');
  }
  let hasFirstError = false;
  for (let i = 0; i < targetFields.length; i++) {
    targetFields[i].errorKeys.removeAll();
    targetFields[i].hasErrorFocus(false);
  }
  return hasFirstError ? false : true;
};

export const setServerError = (targetField, errorMessage) => {
  // server error is always the highest prio, come first in the list
  targetField.errorKeys.unshift('server');
  targetField.validationMessage(errorMessage);
  targetField.hasErrorFocus(true);
};

export const unsetServerError = (targetField, errorMessage) => {
  // server error is always the highest prio, come first in the list
  targetField.errorKeys.remove('server');
};

const makeValidatorObservable = (target, validatorKey, isValidFunc, options) => {
  const validatorMessage = (typeof options === 'object' && options.message) ||
    (typeof options === 'string' && options) ||
    (target.params && target.params[validatorKey]) ||
    validationMessages[validatorKey] || '';

  const onlyIf = typeof options === 'object' && options.onlyIf ? options.onlyIf : null;
  const onErrorCallbackFunc = typeof options === 'object' && options.onError ? options.onError : null;
  const parseMessage = options.parseMessage;

  const parsedValidatorMessage = parseMessage ? parseMessage(validatorMessage) : validatorMessage;

  if (!target.validators) {
    target.validators = {};
  }
  target.validators[validatorKey] = {
    order: target.validators.length + 1,
    message: parseMessage ? parseMessage(validatorMessage) : validatorMessage,
  };

  // an array to keep all error validator keys that do not pass
  if (!target.errorKeys) {
    // @future: this doesn't have to be observableArray actually because no one is watching
    target.errorKeys = ko.observableArray().extend({ deferred: true });
  }
  // a string that contains the highest-prio error message (only one)
  if (!target.validationMessage) {
    target.validationMessage = ko.observable().extend({ deferred: true });
  }
  if (!target.hasErrorFocus) {
    // having defer for this field might block the UI from focusing on that field 
    target.hasErrorFocus = ko.observable(false);
  }
  if (!target.validate) {
    /** 
     * This has to return a promise because we a re dependent on the promise in makeValidatorObservable
     * and wrap it into a deferred timeout to let that promise execute first. 
     */
    target.validate = () => {
      // force valueHasMutates to trigger all subscriber function 
      target.valueHasMutated();
      return new Promise(resolve => setTimeout(() => {
        resolve(!target.hasError());
      }, 0));
    };
  }
  if (!target.hasError) {
    target.hasError = ko.pureComputed(() => {
      return target.errorKeys() && target.errorKeys().length > 0;
    }).extend({ deferred: true });
  }

  // validate whenever the value changes
  target.subscribe((newValue) => {
    let validationResult = isValidFunc(newValue);

    // isValidFunc could be returninga Promise, so forcefully .resolve() it first 
    // to be able to handle the result correctly in all scenarios.
    Promise.resolve(validationResult)
      .then(result => {
        const isValid = () => {
          if (typeof result === 'boolean') {
            return result;
          } else {
            return result.isValid;
          }
        };

        if (isValid() || (onlyIf !== null && !onlyIf.call())) {
          target.errorKeys.remove(validatorKey);

          if (target.errorKeys().length === 0) {
            target.hasErrorFocus(false);
          }

          if (isValid() && typeof result === 'object' && target() !== result.formatted) {
            target(result.formatted);
          }

          return;
        }

        // Remove old one so to make sure the error array are ordered in priority
        const removed = target.errorKeys.remove(validatorKey);
        target.errorKeys.push(validatorKey);

        const hasReordered = removed.length > 0 && target.errorKeys().length > 1;

        // display only new error message that is of higher priority
        if (target.errorKeys().length === 1) {
          target.validationMessage(parsedValidatorMessage);
        } else if (hasReordered) {
          // display the next highest prio error message (if not external error key, e.g. 'server')
          target.validators[target.errorKeys()[0]] && target.validationMessage(target.validators[target.errorKeys()[0]].message);
        }

        if (onErrorCallbackFunc !== null) {
          onErrorCallbackFunc(parsedValidatorMessage);
        }
      })
      .catch(error => {
        throw error;
      });
  });

  // return the original observable
  return target;
};

/**
 * @example ko.observable().extend({required: true})
 * @example ko.observable().extend({required: 'You have to accept these terms and conditions to proceed'})
 */
ko.extenders.required = function (target, options) {
  const isValidFunc = (newValue) => {
    return (typeof newValue === 'string' && newValue.trim()) || newValue ? true : false;
  };

  return makeValidatorObservable(target, 'required', isValidFunc, options);
};

/**
 * @example ko.observable().extend({email: true})
 * @example ko.observable().extend({email: 'Oops, this email does not seem right!'}) 
 */
ko.extenders.email = function (target, options) {
  return makeValidatorObservable(target, 'email', validationRules.validateEmail, options);
};


/**
 * @param {}|Number options : an object that contains options or a number that define the minimum lenght required
 * @param options.value : value of minimum lenght required (inclusive)
 * @param options.message : overriding localized message 
 * 
 * @example ko.observable().extend({minLength: 6 }) //minium 6 char
 * @example ko.observable().extend({minLength: {value: 6, message: 'Your password must include at least {0} characters'}) 
 * 
 */
ko.extenders.minLength = function (target, options) {
  const minLength = typeof options === 'object' && options.value ? options.value : options;
  const isValidFunc = (newValue) => {
    return newValue && newValue.length >= minLength ? true : false;
  };

  let parsedOptions = typeof options === 'object' ? { ...options } : {};
  parsedOptions.parseMessage = (rawMessage) => {
    return rawMessage.replace(/{\d}/, minLength);
  };

  return makeValidatorObservable(target, 'minLength', isValidFunc, parsedOptions);
};

/**
 * @example ko.observable().extend({regex: { pattern: 'xxx', stripSpaces: false, message/key: 'email', lowerCase: false })
 */
ko.extenders.regex = function (target, options) {
  const pattern = options.pattern;
  const stripSpaces = options.stripSpaces || false;
  const lowerCase = options.lowerCase || false;

  const isValidFunc = (newValue) => {
    if (stripSpaces) {
      newValue = newValue.replace(/ /g, '');
      target(newValue);
    }
    if (lowerCase) {
      newValue = newValue.toLowerCase();
      target(newValue);
    }
    return new RegExp(pattern).test(newValue);
  };

  return makeValidatorObservable(target, options.key, isValidFunc, options);
};
