import * as ko from 'knockout';

import dispatcher from '../../Common/appDispatcher';
import {
  notifyTimerEnded,
} from '../../Common/action-creators';


const prefixWithZero = (integer) => {
  if (integer >= 0 && integer < 10) {
    return '0' + integer;
  }
  return integer;
};
ko.components.register('countdown-timer', {
  viewModel: function (params) {
    const self = this;
    const endTime = new Date(Date.UTC(params.year, params.month, params.day, params.hour, params.minute, params.seconds));
    const now = new Date();
    self.timeLeftInMs = ko.observable(endTime.getTime() - now.getTime()); // millisecond left
    self.second = ko.pureComputed(() => {
      const seconds = Math.floor((self.timeLeftInMs() / 1000) % 60);
      return seconds >= 0 ? prefixWithZero(seconds) : '00';
    });
    self.minute = ko.pureComputed(() => {
      const minutes = Math.floor((self.timeLeftInMs() / (1000 * 60)) % 60);
      return minutes >= 0 ? prefixWithZero(minutes) : '00';
    });
    self.hour = ko.pureComputed(() => {
      let hours = Math.floor((self.timeLeftInMs() / (1000 * 60 * 60)) % 24);
      return hours >= 0 ? prefixWithZero(hours) : '00';
    });
    self.day = ko.pureComputed(() => {
      let day = Math.floor(self.timeLeftInMs() / 1000 / 60 / 60 / 24);
      return day >= 0 ? prefixWithZero(day) : '00';
    });
    self.isExpired = ko.pureComputed(() => {
      return self.timeLeftInMs() <= 0 ? true : false;
    });
    self.cssModifier = ko.pureComputed(() => {
      return params.cssModifier;
    });

    self.timerInterval = setInterval(function () {
      const timeDifference = endTime.getTime() - Date.now();
      self.timeLeftInMs(timeDifference);

      if (timeDifference <= 0) {
        clearInterval(self.timerInterval);
        self.timerInterval = null;
        dispatcher.dispatch(notifyTimerEnded());
      }
    }, 1000);

    self.dispose = () => {
      if (self.timerInterval) {
        clearInterval(self.timerInterval);
      }
    };

    return self;
  },
  template: { element: 'countdown-timer-template' },

});
