import * as ko from 'knockout';
const debounce = require('lodash/debounce');

export default ko.components.register('notification', {
  viewModel: {
    createViewModel: (params, componentInfo) => {

      const vm = {
        ...params.notification,
        dismiss: () => params.dismiss(params.notification),
      };

      if (!params.notification.sticky) {
        const prolong = debounce(() => {
          vm.dismiss();
        }, 4000);
        prolong();
        params.notification.text.subscribe(() => {
          prolong();
        });
      }
      // Bouncing effect if the same notification is triggered while being displayed.
      params.notification.text.subscribe(() => {
        componentInfo.element.classList.remove('is-visible');
        setTimeout(() => componentInfo.element.classList.add('is-visible'), 600);
      });

      return vm;
    },
  },
  template: {
    element: 'notification-template',
  },
});
