import ko from 'knockout';

function ScrollTable(el) {
  // ----------------------------------------------------------------------- //
  // selectors                                                               //
  // ----------------------------------------------------------------------- //

  const scrollTable = el;

  // ----------------------------------------------------------------------- //
  // initialize                                                              //
  // ----------------------------------------------------------------------- //

  this.init = function () {
    const dTable = scrollTable.getElementsByTagName('table')[0];
    const headings = document.createElement('DIV');
    headings.className = 'scrollTable__headings';
    scrollTable.appendChild(headings);
    const hTable = document.createElement('TABLE');
    hTable.className = 'table';
    headings.appendChild(hTable);
    const destination = hTable;
    const copy = dTable.cloneNode(true);
    destination.parentNode.replaceChild(copy, destination);

    const rows = scrollTable.querySelectorAll('tbody tr');
    for (let i = 0; i < rows.length; i++) {
      rows[i].addEventListener('mouseover', function () {
        scrollTableMouseOver(scrollTable, this);
      }, false);
      rows[i].addEventListener('mouseout', function () {
        scrollTableMouseOut(scrollTable, this);
      }, false);
    }
  };


  // ----------------------------------------------------------------------- //
  // hover handlers                                                          //
  // ----------------------------------------------------------------------- //


  function scrollTableMouseOver(table, row) {
    setRowColor(table, row, 'rgba(0,0,0,0.1)');
  }

  function scrollTableMouseOut(table, row) {
    setRowColor(table, row, 'none');
  }

  function setRowColor(table, row, color) {
    let i = row.rowIndex + 1;
    if (table.querySelectorAll('table thead').length) {
      i--;
    }
    const rows = table.querySelectorAll('table tbody tr:nth-child(' + i + ')');
    for (let i = 0; i < rows.length; i++) {
      rows[i].style.background = color;
    }
  }
}

// ----------------------------------------------------------------------- //
// bindinghandler                                                          //
// ----------------------------------------------------------------------- //
ko.bindingHandlers.scrollTable = {
  update: (el) => {

    const element = new ScrollTable(el);

    element.init(); // initialize
  },
};