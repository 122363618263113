export const NEWSLETTER_SUBSCRIBE = 'NEWSLETTER_SUBSCRIBE';
export const NEWSLETTER_SUBSCRIBE_SUCCESS = 'NEWSLETTER_SUBSCRIBE_SUCCESS';
export const NEWSLETTER_SUBSCRIBE_ERROR = 'NEWSLETTER_SUBSCRIBE_ERROR';
export const NEWSLETTER_REGISTER_USER_SUCCESS = 'NEWSLETTER_REGISTER_USER_SUCCESS';
export const NEWSLETTER_REGISTER_USER_ERROR = 'NEWSLETTER_REGISTER_USER_ERROR';

import {
  ajax,
} from '../../Common/ajax';

export const newsletterSubscribe = (data) =>
  (dispatch) => {
    dispatch({ type: NEWSLETTER_SUBSCRIBE });
    data.append("subscribedFromFunction", "newsletter page");
    ajax.FORM_POST('/api/v1/newsletter/Subscribe', data).then(
      data => dispatch({ type: NEWSLETTER_SUBSCRIBE_SUCCESS, data }),
      data => dispatch({ type: NEWSLETTER_SUBSCRIBE_ERROR, data })
      // eslint-disable-next-line no-console
    ).catch(err => console.error(err));
  };

export const newsletterRegisterUser = (data) =>
  (dispatch) => {
    dispatch({ type: NEWSLETTER_SUBSCRIBE });
    ajax.FORM_POST('/api/v1/authentication/registeraccount', data).then(
      data =>
        dispatch({ type: NEWSLETTER_REGISTER_USER_SUCCESS, data })
      ,
      data =>
        dispatch({ type: NEWSLETTER_REGISTER_USER_ERROR, data })
      // eslint-disable-next-line no-console
    ).catch(err => console.error(err));
  };


