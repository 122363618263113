/**
 * Load async modules that the main App is dependent upon before we continue init the App
 * @return {Promise} A promise to import the required page module
 */
export function loadModulesAsync() {
   return new Promise((resolve, reject) => {
      const modules: { [key: string]: any } = {};
      const promises = [];

      if (INITIALDATA.listing) {
         promises.push(
            import(/* webpackChunkName: "productListing" */ "../Features/Shared/ProductListing/productListing")
               .then((chunk) => (modules["productListing"] = chunk.default))
               .catch((error) => error)
         );
      }
      if (INITIALDATA.product) {
         promises.push(
            import(/* webpackChunkName: "productVM" */ "../Features/Product/productVM")
               .then((chunk) => (modules["productVM"] = chunk.default))
               .catch((error) => error)
         );
      }

      Promise.all(promises)
         .then(() => {
            resolve(modules);
         })
         .catch((error) => reject(error));
   });
}
