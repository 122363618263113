const tabs = () => {
   const handleTabClick = (container, tab) => {
      // Only handle clicked tab items
      if (!tab.classList.contains('tabs__item') && !tab.classList.contains('tabs__button')) {
         return;
      }

      let selectedIndex = -1;
      let tabItem = 'tabs__item';

      if (tab.classList.contains('tabs__button')) {
         tabItem = 'tabs__button';
      }

      // First remove all selected tabs (and set selected tab index)
      container.querySelectorAll(`.${tabItem}`).forEach((t, idx) => {
         t.classList.remove(`${tabItem}--selected`);

         if (t === tab) {
            selectedIndex = idx;
         }
      });

      // Then set active tab as selected
      if (!tab.classList.contains(`${tabItem}--selected`)) {
         tab.classList.add(`${tabItem}--selected`);
      }

      // Attempt to toggle tab contents
      const tabContents = container.parentNode.querySelectorAll('.tabs-content__item');
      if (tabContents.length === 0) {
         return;
      }

      tabContents.forEach((c) => {
         c.classList.remove('tabs-content__item--active');
      });

      const selectedContent = tabContents[selectedIndex];
      if (!selectedContent.classList.contains('tabs-content__item--active')) {
         selectedContent.classList.add('tabs-content__item--active');
      }
   };

   const init = () => {
      const tabContainers = document.querySelectorAll('.tabs[data-js-selector="new-tabs"]');

      tabContainers.forEach((c) => {
         c.addEventListener('click', (e) => {
            handleTabClick(c, e.target);
         });
      });
   };

   return {
      init,
   };
};

export default tabs();
