import * as ko from 'knockout';

import { scrollToTop } from '../../Common/action-creators';
import dispatcher from '../../Common/appDispatcher';
import { clearFormErrors, validateFormFields } from '../../Validation/validation';
import { CSAT_ERROR, CSAT_SEND, CSAT_SUCCESS, submitCsatForm } from './action-creators';

const vm = {
  errors: ko.observableArray([]),
  isAwaitingResponse: ko.observable(false),
  formRating: ko.observable().extend({ required: true }),
  formResolvedOnFirstAttempt: ko.observable(),
  formEaseOfResolution: ko.observable(),
  formComments: ko.observable(),
  formCaptcha: ko.observable().extend({ required: true }),
  isAfterSubmit: ko.observable(false),
};

vm.resetForm = () => {
  clearFormErrors([vm.formRating, vm.formCaptcha]);

  vm.formRating('');
  vm.formResolvedOnFirstAttempt('');
  vm.formEaseOfResolution('');
  vm.formComments('');
  vm.formCaptcha('');
  vm.isAfterSubmit(false);

  /* eslint-disable-next-line no-undef */
  grecaptcha.reset();
};

vm.submit = (formElem) => {
  const recaptchaResponse = document.querySelector('#recaptcha-response').value;
  vm.formCaptcha(recaptchaResponse);

  validateFormFields([vm.formRating, vm.formCaptcha]).then((result) => {
    if (!result) {
      return;
    }

    if (vm.isAwaitingResponse()) {
      return;
    }

    const payload = new FormData(formElem);

    const params = new URLSearchParams(window.location.search);
    payload.set('TaskId', params.get('taskId') || '');
    payload.set('OrderNo', params.get('orderNo') || '');
    payload.set('Type', params.get('type') || '');
     payload.set('CaseNumber', params.get('case') || '');

    dispatcher.dispatch(submitCsatForm(payload));
  });
};

dispatcher.subscribe((action) => {
  switch (action.type) {
    case CSAT_SEND:
      vm.isAwaitingResponse(true);
      break;
    case CSAT_SUCCESS:
      vm.isAwaitingResponse(false);
      if (action.data.Success) {
        vm.errors([]);
        vm.isAfterSubmit(true);
        dispatcher.dispatch(scrollToTop());
      }
      break;
    case CSAT_ERROR:
      vm.isAwaitingResponse(false);
      if (action.data && action.data.value) {
        vm.errors([action.data.value]);
      }
      break;
  }
});

export default vm;
