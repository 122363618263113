import ko from 'knockout';
import Flickity from '../Vendor/flickity.custom';

/**
 * Binding handler for tracking element on the page, e.g. impressions and clicks
 */
class FlickityProgressHandler {
  update(element, valueAccessor) {
    const flickity = Flickity.data(element);

    if (flickity) {
      handleProgress(element, flickity);
    } else {
      // Hacky way to wait for eventual delay of flickity being initialized
      setTimeout(() => handleProgress(element), 250);
    }
  }
}

function handleProgress(element, flickity) {
  if (!flickity) {
    flickity = Flickity.data(element);

    if (!flickity) {
      return;
    }
  }

  const progressElement = element
    .closest('.js-products-carousel-container')
    .querySelector('.js-carousel-progress');

  const initialProgress = (flickity.selectedElements.length - 1) / (flickity.cells.length - 1);
  progressElement.style.transform = (`scaleX(${initialProgress}`);

  // ref: https://flickity.metafizzy.co/events.html#scroll
  flickity.on('scroll', (progress) => {
    // limit number to range from 0 - 1 as it can go 1- or 1+ with momentum scroll
    let adjustedProgress = Math.max(progress, 0);
    adjustedProgress = Math.min(adjustedProgress, 1);

    // round to 2 decimal
    adjustedProgress = Math.round(adjustedProgress * 100) / 100;

    // KO always start the progress with 0 but we want to it to starts at 1/no.of slides instead
    adjustedProgress = (adjustedProgress * (flickity.slides.length - 1) + 1) / flickity.slides.length;

    progressElement.style.transform = (`scaleX(${adjustedProgress}`);
  });
}

ko.bindingHandlers.flickityProgress = new FlickityProgressHandler();
