import ko from 'knockout';

/**
 * @description To replace an innerText containing {n}, by passed in value or an array of values
 * 
 * @example
 * <div data-bind="replaceText: remainingAmount">Shop {0} more to get free shipping</div>
 */
class ReplaceTextHandler {
  update(element, valueAccessor) {
    const replacement = ko.unwrap(valueAccessor());
    const initialText = element.innerText;
    if (!replacement && replacement !== 0 || !initialText) {
      return;
    }

    if (Array.isArray(replacement)) {
      let replacedText = initialText;
      for (let i = 0; i < replacement.length; i++) {
        const pattern = /{i}/;
        replacedText = replacedText.replace(pattern, replacement[i]);
      }
      element.innerText = replacedText;
    } else {
      const pattern = /{\d}/;
      element.innerText = initialText.replace(pattern, replacement);
    }


  }
}
ko.bindingHandlers.replaceText = new ReplaceTextHandler();
