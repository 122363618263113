import ko from 'knockout';
import dispatcher from '../../Common/appDispatcher';
import { clientModalOpened, OPEN_CLIENT_MODAL } from '../../Common/action-creators';
// Body Scroll Lock ref: https://www.npmjs.com/package/body-scroll-lock
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'; 

const GetModalData = (eventAction, template) => {
  return {
    event: 'modals',
    eventCategory: 'modals',
    eventAction: eventAction,
    modalId: template,
  };
};
const vm = {
  // eslint-disable-next-line no-undef
  closeModal: () => dispatcher.dispatch(closeModal()),
  // eslint-disable-next-line no-undef
  openModal: () => dispatcher.dispatch(openModal()),
  showModal: ko.observable(false),
  leftClientModalTemplate: ko.observable(),
  rightClientModalTemplate: ko.observable(),
  openClientModal: (template, appearFrom = 'right') => {
    if (appearFrom === 'left') {
      vm.leftClientModalTemplate(template);
      vm.rightClientModalTemplate(null);
    } else {
      vm.rightClientModalTemplate(template);
      vm.leftClientModalTemplate(null);
    }
    vm.showModal(true);
    
    const targetEle = document.querySelector(".client-modal--is-open .js-modal-scroll");
    if (targetEle) {
      disableBodyScroll(targetEle);
    }

    if (dataLayer) {
      const data = GetModalData('open', template);
      dataLayer.push(data);
    }
    dispatcher.dispatch(clientModalOpened());
  },
  openClientModalFn: (template, appearFrom) => () => vm.openClientModal(template, appearFrom),
  closeClientModal: () => {
    vm.showModal(false);
    // In mobile site header, multiple BodyScrollLock were enabled in the mobile-menu panes, hence clearAllBodyScrollLocks
    clearAllBodyScrollLocks();
    // Remove after 300ms ($duration--standard) so to give time for transition effect to fade out before removing
    setTimeout(() => {
      vm.leftClientModalTemplate(null);
      vm.rightClientModalTemplate(null);
    }, 300);
  },
  closeClientModalFn: (fn) => () => {
    vm.closeClientModal();
    if (fn && typeof fn === 'function') {
      fn();
    }
  },
};

vm.isAnyModalOpen = ko.computed(function () {
  return vm.leftClientModalTemplate() || vm.rightClientModalTemplate();
}, vm, { pure: true });

dispatcher.subscribe(action => {
  switch (action.type) {
    case OPEN_CLIENT_MODAL:
      vm.openClientModal(action.template, action.appearFrom);
      break;
  }
});

export default vm;
