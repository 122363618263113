function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

function getPromise(url, method, data, isJSON = true, options = null) {
  function fixSwedishChars(str) {
    str = replaceAll(str, '&Aring;', 'Å');
    str = replaceAll(str, '&aring;', 'å');

    str = replaceAll(str, '&Auml;', 'Ä');
    str = replaceAll(str, '&auml;', 'ä');

    str = replaceAll(str, '&Ouml;', 'Ö');
    str = replaceAll(str, '&ouml;', 'ö');
    return str;
  }
  return new Promise(function (resolve, reject) {
    const request = new XMLHttpRequest();
    request.open(method, url);
    if (isJSON) {
      request.setRequestHeader('Accept', 'application/json, charset=UTF-8');
      request.setRequestHeader('Content-Type', 'application/json');
    }
    if (options) {
      if (options.addXMLRequestHeader) {
        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      }
      if (options.accept) {
        request.setRequestHeader('Accept', options.accept);
      }
      if (options.contentType) {
        request.setRequestHeader('Content-Type', options.contentType);
      }
    }

    request.onload = function () {
      if (request.status == 200) {
        try {
          resolve(JSON.parse(request.response));
        } catch (err) {
          if (options && options.raw) {
            resolve(request.response);
          } else {
            resolve({});
          }
        }
      } else {
        try {
          let errorResponse = request.response;
          let parsedErrors;
          try {
            parsedErrors = JSON.parse(errorResponse);
          } catch (e) {
            // invalid JSON
            // eslint-disable-next-line max-len
            parsedErrors = [errorResponse];
          }

          if (window.config && (window.config.isDxc === 'true' || request.getResponseHeader('Errors'))) {
            // var errors = JSON.parse(errorResponse);
            parsedErrors.forEach((error, i) => {
              try {
                parsedErrors[i] = fixSwedishChars(decodeURIComponent(escape(error)));
              } catch (e) {
                // noop
              }
            });
            reject(parsedErrors);
          } else {
            reject(parsedErrors);
          }
        } catch (err) {
          // eslint-disable-next-line max-len
          reject(['Oops! This error shouldn\'t happen, please try again later and contact our customer care if the problem persists.']); // better with localization
        }
      }
    };
    // Handle errors
    request.onerror = function () {
      reject(['Network Error']);
    };

    // Make the requestuest
    if (data) {
      request.send(data);
    } else {
      request.send();
    }

  });
}

// extend this as per the need
export const ajax = {

  GET: function (url) { // url: resourceURL
    return getPromise(url, 'GET');
  },

  AJAX_GET: function (url) {
    return getPromise(url, 'GET', null, true, { addXMLRequestHeader: true });
  },

  HTML_GET: function (url) { // url: resourceURL
    return getPromise(url, 'GET', null, false, {
      addXMLRequestHeader: true,
      raw: true,
      contentType: 'text/html',
      accept: 'text/html',
    });
  },

  POST: function (url, data) { // url: resourceURL, data:payLoad
    return getPromise(url, 'POST', JSON.stringify(data));
  },

  FORM_POST: function (url, data) { // url: resourceURL, data:payLoad
    if (!(data instanceof FormData)) {
      data = new FormData(data);
    }
    return getPromise(url, 'POST', data, false);
  },

  PUT: function (url, data) { // url: resourceURL/{id}, data:payLoad
    return getPromise(url, 'PUT', JSON.stringify(data));
  },

  DELETE: function (url) { // url: resourceURL/{id}
    return getPromise(url, 'DELETE');
  },
};

export default ajax;
