import { getCLS, getFID, getLCP } from 'web-vitals';

function sendToGTM({ name, delta, id }) {
  if (window.dataLayer == null || typeof (window.dataLayer) == 'undefined')
    return;

  let value = Math.round(name === 'CLS' ? delta * 1000 : delta);

  window.dataLayer.push({
    'event': 'webVitalsEvent',
    'eventCategory': 'Web Vitals',
    'eventAction': name,
    'eventValue': value,
    'eventLabel': id,
  });
}

function trackWebVitals() {
  getCLS(sendToGTM);
  getFID(sendToGTM);
  getLCP(sendToGTM);
}

export default trackWebVitals;
