export const PAGE_OBSERVE_IMPRESSION = 'PAGE_OBSERVE_IMPRESSION';
export const PAGE_OBSERVE_CLICK = 'PAGE_OBSERVE_CLICK';

/**
 * Dispatch event to observe supplied element
 * @param {HtmlElement} data 
 */
export const observeImpression = (data) =>
  (dispatch) => {
    dispatch({ type: PAGE_OBSERVE_IMPRESSION, data });
  };

/**
 * Dispatch event to observe supplied element
 * @param {HtmlElement} data 
 */
export const observeClick = (data) =>
  (dispatch) => {
    dispatch({ type: PAGE_OBSERVE_CLICK, data });
  };
