import { ajax } from '../../Common/ajax';

export const CSAT_SEND = 'CSAT_SEND';
export const CSAT_SUCCESS = 'CSAT_SUCCESS';
export const CSAT_ERROR = 'CSAT_ERROR';

export const submitCsatForm = (form) => (dispatch) => {
  dispatch({ type: CSAT_SEND });
  ajax.FORM_POST('/api/v1/csat', form).then(
    (data) => dispatch({ type: CSAT_SUCCESS, data }),
    (data) => dispatch({ type: CSAT_ERROR, data })
  );
};
