import utils from "../../New/Common/utils";
import { removeQuerystringByKeys } from "@afound/common";

const externalLoginDeclinedPermissionModal = document.querySelector("#external-login-declined-permissions-modal");
const externalLoginDeclinedPermissionLink = document.querySelector("#external-login-declined-permissions-link");

const showDeclinedPermissionsModalQuery = "showdeclinedpermissionsmodal";
const providerQuery = "provider";

const closeModal = () => {
   externalLoginDeclinedPermissionModal.classList.remove("client-modal--is-open");   
}

utils.waitForElm("#external-login-declined-permissions-link").then((elm) => {
   externalLoginDeclinedPermissionLink.addEventListener("click", (e) => {  
      e.preventDefault(); 
      closeModal();          
      const query = new URLSearchParams(window.location.search); 
      removeQuerystringByKeys(query, [showDeclinedPermissionsModalQuery, providerQuery]);     
   });
 });
