export const INITIAL_DATA_LOADED = 'INITIAL_DATA_LOADED';
export const HISTORY_UPDATED = 'HISTORY_UPDATED';
export const PAGE_CHANGED_SDP = 'PAGE_CHANGED_SDP';
export const PAGE_OPTION_CHANGED_SDP = 'PAGE_OPTION_CHANGED_SDP';
export const PAGE_SOURCE_SDP = 'PAGE_SOURCE_SDP';
export const PAGE_SCROLL_TOP = 'PAGE_SCROLL_TOP';
export const PAGE_SCROLL_BOTTOM = 'PAGE_SCROLL_BOTTOM';
export const PAGE_SCROLL_TO_POS = 'PAGE_SCROLL_TO_POS';
export const DATE_UPDATED = 'DATE_UPDATED';
export const OPEN_CLIENT_MODAL = 'OPEN_CLIENT_MODAL';
export const CLIENT_MODAL_OPENED = 'CLIENT_MODAL_OPENED';
export const TIMER_ENDED = 'TIMER_ENDED';

export const openClientModal = (template, appearFrom) => ({
  type: OPEN_CLIENT_MODAL,
  template,
  appearFrom,
});

export const clientModalOpened = () => ({
  type: CLIENT_MODAL_OPENED,
});

export const initialData = (data) => ({
  type: INITIAL_DATA_LOADED,
  data,
});

export const historyChange = (data) => ({
  type: HISTORY_UPDATED,
  data,
});

export const sdpPageChange = (data) => ({
  type: PAGE_CHANGED_SDP,
  data,
});

export const sdpPageOptionChange = (data) => ({
  type: PAGE_OPTION_CHANGED_SDP,
  data,
});

export const scrollToTop = () => ({
  type: PAGE_SCROLL_TOP,
});

export const scrollToBottom = () => ({
  type: PAGE_SCROLL_BOTTOM,
});

export const scrollToPos = (data) => ({
  type: PAGE_SCROLL_TO_POS,
  data,
});

export const dateUpdated = (data) => ({
  type: DATE_UPDATED,
  data,
});

export const notifyTimerEnded = () => ({
  type: TIMER_ENDED,
});

window.initialData = initialData;
